import React from 'react';

import cn from 'classnames';

import styles from './AgeLimit.module.css';

import type { Props } from './types';
import type { FC } from 'react';

const AgeLimit: FC<Props> = ({
    className,
    age = '18',
    color = 'green',
    size = '48',
    form = 'square',
}) => (
    <div
        className={cn(styles.root, className, {
            [styles[`form_${form}`]]: form,
            [styles[`color_${color}`]]: color,
            [styles[`size_${size}`]]: size,
        })}
    >
        {age}
        +
    </div>
);

export default AgeLimit;
