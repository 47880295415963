import type { ComponentType } from 'react';

export const isString = (value: unknown): value is string => typeof value === 'string';

export const isNumber = (value: unknown): value is number => typeof value === 'number' && !Number.isNaN(value);

export const isArray = (value: unknown): value is Array<unknown> => Array.isArray(value);

export const isObject = (value: unknown): value is Record<string, unknown> => typeof value === 'object'
    && value !== null;

export const isComponentType = (value: unknown): value is ComponentType => (typeof value === 'object'
    && value !== null && Object.prototype.hasOwnProperty.call(value, 'render'));
