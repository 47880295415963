import React, { forwardRef } from 'react';

import cn from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import styles from './Tooltip.module.css';

import type { TooltipWrapperProps } from './types';
import type { TooltipRefProps } from 'react-tooltip';

const TooltipWrapper = forwardRef<TooltipRefProps, TooltipWrapperProps>((props, ref) => {
    const {
        id,
        children,
        content,
        appearance = 'neutral',
        className,
        ...restProps
    } = props;

    return (
        <div data-tooltip-id={id} className={cn(styles.wrapper, className)}>
            {children}
            {(!!content) && (
                <ReactTooltip
                    noArrow
                    {...restProps}
                    className={cn(styles.tooltip, styles[`appearance-${appearance}`])}
                    opacity="1"
                    id={id}
                    ref={ref}
                >
                    {content}
                </ReactTooltip>
            )}
        </div>
    );
});

export default TooltipWrapper;
