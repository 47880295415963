import React, { forwardRef } from 'react';

import BaseInput from './BaseInput';

import type { RefElementType, TextareaProps } from './types';
import type { FC } from 'react';

const Textarea: FC<TextareaProps> = forwardRef<RefElementType, TextareaProps>(({
    disabled,
    ...restProps
}, ref) => (
    <BaseInput
        isDisabled={disabled}
        {...restProps}
        component="textarea"
        {...(ref && { ref })}
    />
));

export default Textarea;
