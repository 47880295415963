import React from 'react';

import { ICONS } from './constants';

import type { IconProps } from './types';
import type { FC } from 'react';

const Icon: FC<IconProps> = ({ name, ...restProps }) => {
    if (!ICONS[name]) {
        return null;
    }

    const IconComponent = ICONS[name];

    return (
        <IconComponent
            {...restProps}
        />
    );
};

export default Icon;
