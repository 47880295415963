import { isComponentType } from '../../@types/typeguards';

import type { ComponentType } from 'react';

export const modules = import.meta.glob('./svg/*.svg', {
    import: 'default',
    eager: true,
});

const ICONS = Object.entries(modules).reduce<Record<string, ComponentType>>((acc, [path, mod]) => {
    const svgNameRegexp = /svg\/(.*)\.svg/;
    const name = path.match(svgNameRegexp)?.[1] || path;

    if (!isComponentType(mod)) {
        return acc;
    }

    acc[name] = mod;

    return acc;
}, {});

type ICON_KEYS = keyof typeof ICONS;

export { ICONS };
export type { ICON_KEYS };
