// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = (callback: (...args: any[]) => void, delay: number) => {
    let timeout: NodeJS.Timeout;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (...args: any[]) => {
        clearTimeout(timeout);

        timeout = setTimeout(() => callback(...args), delay);
    };
};

export default debounce;
