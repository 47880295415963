// @ts-nocheck

const HashUtils = {
    LongMath: {
        add64: function(a, b) {
            const sum = { h: a.h + b.h, l: a.l + b.l };
            let ofl = 0;

            ofl = (sum.l / 0x100000000) >>> 0;
            sum.l = (sum.l & 0xffffffff) >>> 0;
            sum.h = ((sum.h + ofl) & 0xffffffff) >>> 0;

            return sum;
        },

        mul64: function(a, b) {
            const l  = this.mul32(a.l, b.l),
                m1 = this.mul32(a.h, b.l),
                m2 = this.mul32(a.l, b.h);
            const res = {
                h: ((l.h + m1.l + m2.l) & 0xffffffff) >>> 0,
                l: l.l
            };

            return res;
        },

        mul32: function(a, b) {
            const ah = (a >> 16) & 0xffff, al = a & 0xffff,
                bh = (b >> 16) & 0xffff, bl = b & 0xffff;
            const l  = al * bl, m1 = ah * bl, m2 = al * bh, h  = ah * bh;
            const res_l = ((l + ((m1 + m2) << 16) >>> 0) & 0xffffffff) >>> 0;
            const overflow = ((l + (m1 + m2) * 0x10000 - res_l) / 0x100000000) >>> 0;
            const res_h = h + overflow;

            return { h: res_h, l: res_l };
        }
    },

    SipHash: function() {

        function _add(a, b) {
            const rl = a.l + b.l,
                a2 = { h: a.h + b.h + (rl / 2 >>> 31) >>> 0,
                    l: rl >>> 0 };

            a.h = a2.h; a.l = a2.l;
        }

        function _xor(a, b) {
            a.h ^= b.h; a.h >>>= 0;
            a.l ^= b.l; a.l >>>= 0;
        }

        function _rotl(a, n) {
            const a2 = {
                h: a.h << n | a.l >>> (32 - n),
                l: a.l << n | a.h >>> (32 - n)
            };

            a.h = a2.h; a.l = a2.l;
        }

        function _rotl32(a) {
            const al = a.l;

            a.l = a.h; a.h = al;
        }

        function _compress(v0, v1, v2, v3) {
            _add(v0, v1);
            _add(v2, v3);
            _rotl(v1, 13);
            _rotl(v3, 16);
            _xor(v1, v0);
            _xor(v3, v2);
            _rotl32(v0);
            _add(v2, v1);
            _add(v0, v3);
            _rotl(v1, 17);
            _rotl(v3, 21);
            _xor(v1, v2);
            _xor(v3, v0);
            _rotl32(v2);
        }

        function _get_int(a, offset) {
            return a.charCodeAt(offset + 3) << 24 |
                   a.charCodeAt(offset + 2) << 16 |
                   a.charCodeAt(offset + 1) << 8 |
                   a.charCodeAt(offset);
        }

        function hash(key, m) {
            const k0 = { h: key[1] >>> 0, l: key[0] >>> 0 };
            const k1 = { h: key[3] >>> 0, l: key[2] >>> 0 };
            const v0 = { h: k0.h, l: k0.l };
            const v2 = k0;
            const v1 = { h: k1.h, l: k1.l };
            const v3 = k1;
            const ml = m.length;
            const ml7 = ml - 7;
            const buf = new Uint8Array(new ArrayBuffer(8));
            let mi;
            let mp = 0;

            _xor(v0, { h: 0x736f6d65, l: 0x70736575 });
            _xor(v1, { h: 0x646f7261, l: 0x6e646f6d });
            _xor(v2, { h: 0x6c796765, l: 0x6e657261 });
            _xor(v3, { h: 0x74656462, l: 0x79746573 });
            while (mp < ml7) {
                mi = { h: _get_int(m, mp + 4), l: _get_int(m, mp) };
                _xor(v3, mi);
                _compress(v0, v1, v2, v3);
                _compress(v0, v1, v2, v3);
                _xor(v0, mi);
                mp += 8;
            }
            buf[7] = ml;
            let ic = 0;

            while (mp < ml) {
                buf[ic++] = m.charCodeAt(mp++);
            }
            while (ic < 7) {
                buf[ic++] = 0;
            }
            mi = { h: buf[7] << 24 | buf[6] << 16 | buf[5] << 8 | buf[4],
                l: buf[3] << 24 | buf[2] << 16 | buf[1] << 8 | buf[0] };
            _xor(v3, mi);
            _compress(v0, v1, v2, v3);
            _compress(v0, v1, v2, v3);
            _xor(v0, mi);
            _xor(v2, { h: 0, l: 0xff });
            _compress(v0, v1, v2, v3);
            _compress(v0, v1, v2, v3);
            _compress(v0, v1, v2, v3);
            _compress(v0, v1, v2, v3);

            const h = v0;

            _xor(h, v1);
            _xor(h, v2);
            _xor(h, v3);

            return h;
        }

        function string16_to_key(a) {
            return [_get_int(a, 0), _get_int(a, 4),
                _get_int(a, 8), _get_int(a, 12)];
        }

        function hash_hex(key, m) {
            const r = hash(key, m);

            return ('0000000' + r.h.toString(16)).substr(-8) +
                   ('0000000' + r.l.toString(16)).substr(-8);
        }

        function hash_uint(key, m) {
            const r = hash(key, m);

            return (r.h & 0x1fffff) * 0x100000000 + r.l;
        }

        return {
            string16_to_key: string16_to_key,
            hash: hash,
            hash_hex: hash_hex,
            hash_uint: hash_uint
        };
    }(),

    jumphash: function(key, numBuckets) {
        const multiplier = { h: 0x27bb2ee6, l: 0x87b0b0fd };
        let j = 0;
        let b = -1;

        while (j < numBuckets) {
            b = j;
            key = HashUtils.LongMath.mul64(key, multiplier);
            key = HashUtils.LongMath.add64(key, { h: 0, l: 1 });
            j = Math.floor((b + 1) * (2147483648 / ((key.h >>> 1) + 1)));
        }

        return b;
    },

    getShard: function(key, total) {
        return HashUtils.jumphash(HashUtils.SipHash.hash(0, key), total);
    }

};

export {
    HashUtils,
    HashUtils as default,
};