// @ts-nocheck
const MAILRU_RGX = /^[^@]+@(mail\.ru|xmail\.ru|list\.ru|inbox\.ru|bk\.ru|internet\.ru|corp\.mail\.ru|mail\.ua|vk\.com|vk\.team|vkteam\.ru|vk\.ru|corp\.vk\.ru|corp\.my\.com)$/;
const CORP_RGX = /^[^@]+@(corp\.mail.ru|corp\.vk\.com|vk\.team|vkteam\.ru)$/;
const MYGAMES_RGX = /^[^@]+@(my\.games|guestmg(?:0[1-4])?\.pro)$/;

export type Callback = () => void;
export type Game = {
    gc_id: string,
};

export interface IQsOptions {
    addQueryPrefix: boolean;
}

export const MYGAMES_DOMAIN = 'my.games';
export const MYGAMES_DEV_DOMAIN = 'dev-my.games';
export const ASTRUM_DOMAIN = 'astrum.top';
export const ASTRUM_DOMAIN_RU = 'astrum-entertainment.ru';
export const GMR_DOMAIN = 'games.mail.ru';
export const GMR_GM2_DOMAIN = 'games.gm2.corp.mail.ru';
export const GMR_GM3_DOMAIN = 'games.gm3.corp.mail.ru';
export const GMR_GM_HOST = window.location.hostname.match(/gm\d/);
export const MAILRU_DOMAIN = 'mail.ru';
export const MYCOM_DOMAIN = 'my.com';
export const SUPPORT_DOMAIN = 'support.my.games';
export const VKPLAY_DOMAIN = 'vkplay.ru';
export const VKPLAY_LIVE_DOMAIN = 'vkplay.live';
export const VKPLAY_DEV_DOMAIN = 'dev-vkplay.ru';

export const isDevMode = (): boolean => {
    return process.env.NODE_ENV === 'development';
};
export const isProductionMode = process.env.NODE_ENV === 'production';
export const isDevGem = window.location.hostname.endsWith(MYGAMES_DEV_DOMAIN) || window.location.hostname.endsWith(VKPLAY_DEV_DOMAIN);
export const isDevMyGames = window.location.hostname.endsWith(MYGAMES_DEV_DOMAIN);
export const isDevVkPlay = window.location.hostname.endsWith(VKPLAY_DEV_DOMAIN);
export const isVkPlayRu = window.location.hostname.endsWith(VKPLAY_DOMAIN);
export const isVkPlayLive = window.location.hostname.endsWith(VKPLAY_LIVE_DOMAIN);
export const isVkPlay = isVkPlayRu || isVkPlayLive || window.isVkp;
export const isMyGames = window.location.hostname.endsWith(MYGAMES_DOMAIN);
export const isAstrum = window.location.hostname.endsWith(ASTRUM_DOMAIN) || window.location.hostname.endsWith(ASTRUM_DOMAIN_RU);
export const isGem = isMyGames || isVkPlay;
export const isGMR = window.location.hostname.endsWith(GMR_DOMAIN) || window.location.hostname.endsWith(GMR_GM2_DOMAIN) || window.location.hostname.endsWith(GMR_GM3_DOMAIN);
export const isDevDomain = isDevGem && isGem;
export const isMailRu = window.location.hostname.endsWith(MAILRU_DOMAIN);
export const isMyCom = window.location.hostname.endsWith(MYCOM_DOMAIN);
export const isAtTest = getCookie('at_test') === '1';
export const isGm2 = location.origin.includes('gm2');
export const isGm3 = location.origin.includes('gm3');
export const isChatPage = location.pathname.includes('/chat');
export const isGCUserAgent = navigator.userAgent.match(/\bDownloader\/\d+\b/);
const isGamecenterPage = location.pathname.includes('/gamecenter/') || location.search.includes('in_gamecenter=1');

export const isVkPlayMedia = window.location.hostname.includes('media.');

export const DOMAIN = isDevMyGames ? MYGAMES_DEV_DOMAIN :
    isDevVkPlay ? VKPLAY_DEV_DOMAIN :
        isVkPlay ? VKPLAY_DOMAIN :
            isMailRu ? MAILRU_DOMAIN :
                isMyGames ? MYGAMES_DOMAIN :
                    isMyCom ? MYCOM_DOMAIN :
                        isAstrum ? ASTRUM_DOMAIN :
                            VKPLAY_DOMAIN;

export const API_DOMAIN = `api.${DOMAIN}`;

export const domain_rgx = new RegExp(`^https?://([^/]+\.)?${DOMAIN}`);

export const hostname_rgx = new RegExp(
    '^([^.]+-)?(?:([^.-]+)\.)?((?:gm[123]|test[12])\.)?(dev-)?(my.games|vkplay.ru|astrum.top)$'
);

export function getGemHost(project: string) : string {
    let match = hostname_rgx.exec(window.location.hostname);

    if (!match) {
        if (window.location.hostname.endsWith('dev-vkplay.ru')) {
            match = hostname_rgx.exec('store.dev-vkplay.ru');
        } else {
            match = hostname_rgx.exec(isMyGames ? MYGAMES_DOMAIN : VKPLAY_DOMAIN);
        }
    }

    const alias = match[1] || '';
    const dev = match[3] || '';
    const stage = match[4] || '';
    const domain = match[5];

    const is_dev = match[3] !== undefined;
    const is_stage = match[4] !== undefined;
    const is_prod = !is_dev && !is_stage;

    if (project === 'media') {
        project = 'store'; // медиа теперь живет на том же домене
    }

    if (is_prod && !isMyGames && project === 'store') {
        return domain;
    }

    if (is_prod && !isMyGames && project === 'chat') {
        return `chat.${domain}`;
    }

    return `${alias}${project}.${dev}${stage}${domain}`;
}

export function getAuthHost(project: string) : string {
    let match = hostname_rgx.exec(window.location.hostname);

    if (!match) {
        if (window.location.hostname.endsWith('dev-vkplay.ru')) {
            match = hostname_rgx.exec('store.dev-vkplay.ru');
        } else {
            match = hostname_rgx.exec(isMyGames ? MYGAMES_DOMAIN : VKPLAY_DOMAIN);
        }
    }

    const stage = match[4] || '';
    const domain = match[5];

    return `${project}.${stage}${domain}`;
}

export const inGamecenter = (() : boolean => {
    if (window.location.pathname.indexOf('/gamecenter/download') > -1) {
        return false;
    }

    return (
        isGamecenterPage ||
        isGCUserAgent ||
        window.IN_GAMECENTER
    );
})();

export const isWindows = (() : boolean => {
    return navigator.platform.toLowerCase().indexOf('win') > -1;
})();

export const isMac = (() : boolean => {
    return /Macintosh/i.test(navigator.userAgent);
})();

export const isMacGC = (() : boolean => {
    return inGamecenter && isMac;
})();

export const isProduction = (() : boolean => {
    return (
        (isGem && !isGm3) ||
        window.location.hostname.endsWith(GMR_DOMAIN) ||
        document.domain === 'games.my.com'
    );
})();

export const isMobile = (() : boolean => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
})();

export const isMailRuEmail = (user: string): boolean => MAILRU_RGX.test(user);
export const isMyGamesEmail = (user: string): boolean => MYGAMES_RGX.test(user);
export const isCorpUser = (user: string): boolean => CORP_RGX.test(user);

export function origin_by_login(login: string): ?string {
    if (!login) return '';
    if (isMailRuEmail(login)) return 'mailru';
    if (login.endsWith('@vk')) return 'vk';
    if (login.endsWith('@ok')) return 'ok';
    if (!isNaN(login)) return 'p';
    if (login.endsWith('@facebook')) return 'fb';
    if (login.endsWith('@twitter')) return 'tw';
    if (login.endsWith('@discord')) return 'ds';
    if (login.endsWith('@google')) return 'g';
    if (login.endsWith('@steam')) return 'steam';
    if (login.endsWith('@twitch')) return 'twitch';
    if (login.endsWith('@hitbox')) return 'hitbox';
    if (login.endsWith('@ps')) return 'ps';
    if (login.endsWith('@xbox')) return 'xbox';
    if (login.endsWith('@apple')) return 'apple';
    if (login.endsWith('@eg')) return 'eg';
    if (login.endsWith('@pl')) return 'pl';
    if (login.endsWith('@ns')) return 'ns';
    if (login.endsWith('@astrum')) return 'astrum';
    if (login.endsWith('@vkplay')) return 'vkplay';
    if (isMyGamesEmail(login)) return 'mygames';
    if (login.indexOf('@') > 0) return 'e';
    console.error(`unknown origin for login: '${login}'`);
}

const getGemShards = () => {
    const GEM_DEV_SHARDS = [`ws0.${API_DOMAIN}`];
    const GEM_GM2_SHARDS = [`ws0.gm2.${DOMAIN}`, `ws1.gm2.${DOMAIN}`];
    const GEM_GM3_SHARDS = [`ws0.gm3.${DOMAIN}`];
    const GEM_PROD_SHARDS = [
        `wss0.${API_DOMAIN}`,
        `wss1.${API_DOMAIN}`,
        `wss2.${API_DOMAIN}`,
        `wss3.${API_DOMAIN}`,
        `wss4.${API_DOMAIN}`,
        `wss5.${API_DOMAIN}`
    ];
    const GEM_PROD_EU_SHARDS = [
        `eu-ws0.${API_DOMAIN}`,
        `eu-ws1.${API_DOMAIN}`,
        `eu-ws2.${API_DOMAIN}`,
        `eu-ws3.${API_DOMAIN}`,
        `eu-ws4.${API_DOMAIN}`,
        `eu-ws5.${API_DOMAIN}`
    ];

    if (isDevGem) {
        return isGm3 ? GEM_GM3_SHARDS : GEM_DEV_SHARDS;
    }

    if (isProduction) {
        if (isMyGames) {
            return GEM_PROD_EU_SHARDS;
        }

        return GEM_PROD_SHARDS;
    }


    return GEM_GM2_SHARDS;
};

const getGMRShards = () => {
    const GMR_GM2_SHARDS = ['ws0.games.gm2.corp.mail.ru', 'ws1.games.gm2.corp.mail.ru'];
    const GMR_PROD_SHARDS = [
        `ws0.${GMR_DOMAIN}`,
        `ws1.${GMR_DOMAIN}`,
        `ws2.${GMR_DOMAIN}`,
        `ws3.${GMR_DOMAIN}`,
        `ws4.${GMR_DOMAIN}`,
        `ws5.${GMR_DOMAIN}`
    ];

    if (isProduction) {
        return GMR_PROD_SHARDS;
    }

    return GMR_GM2_SHARDS;
};

export const getShards = (): Array => {
    if (isGem) {
        return getGemShards();
    } else {
        return getGMRShards();
    }
};

export const Domains = {
    wsd: getShards()
};

export const isMyGamesApp = ((): boolean => {
    const ua = navigator.userAgent;
    const qs = query_string();

    return ua.indexOf('com.my.mygamesapp') > -1 || qs.is_mygames_app === '1';
})();

export function showAuthBlock(opts?: string | {
    fromUrl?: string;
    continueUrl?: string;
}): void {
    const continueUrl = typeof opts === 'string' ? opts : opts?.continueUrl;
    const fromUrl = opts && typeof opts !== 'string' && opts.fromUrl;
    const continueParam = continueUrl ? `&continue=${encodeURIComponent(continueUrl)}` : '';

    if (isMyGamesApp) {
        messageHandler.postMessage('LOGIN');
    } else if (inGamecenter) {
        window.gc_show_auth_window && window.gc_show_auth_window();
    } else {
        window.location.href = `https://account.vkplay.ru/login?from=${encodeURIComponent(fromUrl || window.location.href)}${continueParam}`;
    }
}

export function showSignupBlock(opts?: string | {
    fromUrl?: string;
    continueUrl?: string;
}): void {
    showAuthBlock(opts);
}

export function build_api_url(path: string, localhost_origin: string): string {
    path = path || '/';

    const domain = window.location.hostname.replace(/^m([-.])/, '');

    if (domain?.endsWith('my.com')) {
        if (domain?.endsWith('dev.my.com')) {
            return '//api-' + domain + path;
        }

        return '//api.games.my.com' + path;
    } else if (domain?.endsWith('corp.mail.ru')) {
        const developer = window.location.hostname.split('.')[0];

        return `//${developer}-api.games.${GMR_GM_HOST}.corp.mail.ru${path}`;
    } else if (domain?.endsWith('dev-my.games')) {
        return `//api.dev-my.games${path}`;
    } else if (isGem) {
        return '//' + getGemHost('api') + path;
    } else if (window.location.hostname === 'localhost') {
        return `${localhost_origin}${path}`;
    }

    return '//api.games.mail.ru' + path;
}

export function build_url(path: string): string {
    path = path || '/';

    if (isGem) {
        if (path.indexOf('/profile/') > -1) {
            return '//' + getGemHost('profile') + path;
        }
    } else if (window.location.hostname.endsWith('corp.mail.ru')) {
        const domain = window.location.hostname.split('.');

        let url = 'games.gm2.corp.mail.ru';

        if (domain[0] !== 'bonus' && domain[0] !== 'market') {
            if (domain[0].length === 1) {
                url = domain[0] + '.' + domain[1] + '.' + url;
            } else {
                url = domain[0] + '.' + url;
            }
        } else {
            return '//games.mail.ru' + path;
        }

        return '//' + url + path;
    }

    return '//games.mail.ru' + path;
}

export function openGCUrl(e: Event, url: string): void {
    if (inGamecenter && window.gc_open_url_mainwnd) {
        url = url || e.target.href;

        if (url) {
            e.preventDefault();

            if (url.indexOf('//') === 0) {
                url = 'https:' + url;
            }

            window.gc_open_url_mainwnd(url);
        }
    }
}

export function rbShow(clb: string): void {
    clb = String(clb || '')
        .replace(/^\s*(cl[nb])?/, '')
        .replace(/\s*$/, '');
    new Image().src = '//rs.mail.ru/d' + clb + '.gif?rnd=' + Math.random();
}

export function rbClick(clb: string): void {
    clb = String(clb || '')
        .replace(/^\s*(cl[nb])?/, '')
        .replace(/\s*$/, '');
    new Image().src = '//rs.mail.ru/sb' + clb + '.gif?rnd=' + Math.random();
}

export function getGoogleCID(ga: string): ?string {
    if (typeof ga === 'undefined') {
        ga = getCookie('_ga');
    }

    const match = ga.match(/(\d+\.\d+)$/);

    return (match) ? match[1] : undefined;
}

export function buildGcHttpUrl(url = ''): string {
    return 'mailrugames://urlhttps' + '/' + 'games.mail.ru' + url;
}

export function absoluteUrl(url: string): string {
    if (url.startsWith('//')) {
        url = document.location.protocol + url;
    } else if (!/^https?:\/\//i.test(url)) {
        url = document.location.origin + url;
    }

    return url;
}

export function safeUrl(url: string): string {
    if (!url) {
        return '';
    }

    url = absoluteUrl(url);

    if (url.search(domain_rgx) === 0) {
        return url;
    } else if (url.startsWith(window.location.origin)) {
        return url;
    }

    return '';
}

export const safeRedirectUrl = (url: string): string => {
    if (!/^[\w\-]+:\/\/([\w\-]+\.)*[\w\-]+(:\d+)?(\/.*)?$/.test(url)) {
        throw new Error(`unsafe redirect url: ${url}`);
    }

    return url;
};

export function triggerPixel(url: string): void {
    if (url && url !== '') {
        const rndParam = url.indexOf('?') > -1 ? '&rnd=' : '?rnd=';
        const cookieBotLoaded: boolean|undefined = window.gem_cookiebot_loaded;
        const checkCookieBot = !cookieBotLoaded || (cookieBotLoaded && window.Cookiebot?.consent?.statistics);

        if (checkCookieBot) {
            new Image().src = url + rndParam + Math.random();
        }
    }
}

export function getCookie(name: string): ?string {
    const matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));

    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name: string, value: string, options = {}): void {
    options = options || {};

    let expires = options.expires;

    if (typeof expires === 'number' && expires) {
        const d = new Date();

        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + '=' + value;

    for (const propName in options) {
        if (options[propName]) {
            const propValue = options[propName];

            updatedCookie += '; ' + propName;

            if (propValue !== true) {
                updatedCookie += '=' + propValue;
            }
        }
    }

    document.cookie = updatedCookie;
}

export function htmlEscape(str = ''): string {
    if (!str) {
        return '';
    }

    return str
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\//g, '&#x2F;');
}

export function htmlUnescape(str = ''): string {
    if (!str) {
        return '';
    }

    return str
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, '\'')
        .replace(/&apos;/g, '\'')
        .replace(/&#x27;/g, '\'')
        .replace(/&lt;/g, '<')
        .replace(/&#60;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&#62;/g, '>')
        .replace(/&#x2F;/g, '/')
        .replace(/&amp;/g, '&');
}

export function query_string(url?: string): object {
    const qs = {};
    const pairs = (url || window.location.search || '').replace(/^[^?]*\?/, '').split('&');

    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i],
            idx = pair.indexOf('='),
            key = decodeURIComponent(pair.substr(0, idx));

        if (key) {
            qs[key] = decodeURIComponent(pair.substr(idx + 1));
        }
    }

    return qs;
}

export function qsStringify(srcObj = {}, options: IQsOptions = {}): string {
    const { addQueryPrefix = false } = options;

    if (typeof srcObj === 'object') {
        return Object
            .keys(srcObj)
            .reduce((acc, cur) => {
                const valid = srcObj[cur] !== undefined && srcObj[cur] !== '' && srcObj[cur] !== null;

                if (valid) {
                    if (addQueryPrefix && acc.length === 0) {
                        acc += '?';
                    } else if ((addQueryPrefix && acc.length > 0) || acc.length > 0) {
                        acc += '&';
                    }
                    acc += `${cur}=${encodeURIComponent(srcObj[cur])}`;
                }

                return acc;
            }, '');
    }

    return '';
}

export function declineNum(num: number, one: string, few: string, many: string): string {
    let count;

    if (num % 100 === 11 || num % 100 === 12 || num % 100 === 13 || num % 100 === 14) {
        count = many;
    } else if (num % 10 === 1) {
        count = one;
    } else if (num % 10 >= 2 && num % 10 <= 4) {
        count = few;
    } else if (num % 10 === 0 || (num % 10 >= 5 && num % 10 <= 9)) {
        count = many;
    }

    return count;
}

export function resizePic(
    src: string,
    params?: {
        width?: number,
        height?: number,
        quality?: number,
        format?: 'jpeg'|'webp'|'png',
        blur?: number,
        feather?: number,
        color?: string,
        method?: 'crop'|'resize'
    } = {}
): string {
    let size: string;

    const method = params.method || 'crop';
    const { quality = 85, format, blur: feather_blur, feather, color: feather_color } = params;

    const newParams = qsStringify({
        ...!isNaN(quality) && quality < 100 && { quality },
        ...format && { format },
        ...feather_blur && { feather_blur },
        ...feather && { feather },
        ...feather_color && { feather_color },
    });

    if (params && (params.width || params.height)) {
        size = params.width + 'x' + params.height;
    } else {
        size = 'big2';
    }

    if (src) {
        src += newParams.length > 0 ? (src.includes('?') ? '&' : '?') + newParams : '';

        if (src.indexOf('/hotbox/') > -1) {
            src = src.replace('/hotbox/', `/pre_${size}_${method}/hotbox/`);
        } else if (src.indexOf('/pic/') > -1) {
            src = src.replace('/pic/', `/pre_${size}_${method}/pic/`);
        }

        return src;
    }
}

// Date format

export function isValidDate(d: Date): boolean {
    if (Object.prototype.toString.call(d) !== '[object Date]') {
        return false;
    }

    return !isNaN(d.getTime());
}

// Size format

export function humanFileSize(size: number, units = {}, decimal: number): string {
    let i = 0;

    if (!units) {
        return;
    }

    const measure = decimal ? 1000 : 1024; // decimal or binary

    while (size > 1000) {
        size /= measure;
        i++;
    }

    return size.toFixed(1) + ' ' + units[i];
}

interface FormatNumberOptions {
    decimalSignType?: 'point' | 'comma';
    postfix?: string;
    prefix?: string;
    separateThousandsWith?: string;
}

export function formatNumber(value: string | number, {
    decimalSignType = 'point',
    postfix = '',
    prefix = '',
    separateThousandsWith,
}: FormatNumberOptions): string {
    let inputNumber = value.toString();

    if (decimalSignType === 'comma') {
        inputNumber = inputNumber.replace('.', ',');
    }

    if (separateThousandsWith) {
        const thousandSeparatorRegexp = /(\d)(?=(\d\d\d)+(?!\d))/g;

        inputNumber = inputNumber.replace(thousandSeparatorRegexp, `$1${separateThousandsWith}`);
    }

    return `${prefix}${inputNumber}${postfix}`;
}

// Price currency format

const PRICE_CONFIGS = {
    'USD': {
        separateThousandsWith: ' ',
        prefix: '$'
    },
    'EUR': {
        separateThousandsWith: ' ',
        prefix: '€'
    },
    'RUB': {
        separateThousandsWith: ' ',
        decimalSignType: 'comma',
        postfix: ' ₽'
    }
};

export function formatPrice(value: string | number, currency: string): string | null {
    return PRICE_CONFIGS[currency]
        ? formatNumber(value, PRICE_CONFIGS[currency])
        : null;
}

// Get random number from range

export function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const createThrottlingDecorator = (func: Callback, timeout = 1000): Callback => {
    let isThrottled = false;
    let savedArgs;
    let savedThis;

    function wrapper(...args) {
        if (isThrottled) {
            savedArgs = args;
            savedThis = this;

            return;
        }

        func(...args);

        isThrottled = true;

        setTimeout(function() {
            isThrottled = false;

            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, timeout);
    }

    return wrapper;
};

export const createDebounceDecorator = (func: Callback, timeout = 1000): Callback => {
    let isCooldown = false;

    return function(...args) {
        if (isCooldown) return;

        func(...args);

        isCooldown = true;

        setTimeout(() => isCooldown = false, timeout);
    };
};

export const createCallLastDecorator = (func: Callback, timeout = 1000): Callback => {
    let counter = null;

    return function(...args) {
        if (counter) {
            clearTimeout(counter);
        }

        counter = setTimeout(() => func(...args), timeout);
    };
};

export const copyTextToClipboardIE11 = (text: string, handleSuccessCopy: Callback): void => {
    const textArea = document.createElement('textarea');

    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.opacity = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);

    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');

    if (handleSuccessCopy) {
        handleSuccessCopy();
    }

    document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text: string, handleSuccessCopy: Callback): void => {
    if (window.gc_clipboard_copy) {
        window.gc_clipboard_copy(text);
        handleSuccessCopy && handleSuccessCopy();
    } else if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
            .then(handleSuccessCopy)
            .catch(() => copyTextToClipboardIE11(text, handleSuccessCopy));
    } else {
        copyTextToClipboardIE11(text, handleSuccessCopy);
    }
};

export const CIS_REGIONS: Array<string> = ['398', '219', '215', '201', '29', '28', '34', '191', '188', '17', '33', '16', '196', '467'];

export const isCis = (): boolean => CIS_REGIONS.includes(window.country_id);

export const allowCloud = (data = {}): boolean => {
    const allowCorp: boolean = data.login && isCorpUser(data.login);

    // 5506 - Москва
    // 5560 - Санкт-Петербург
    // 188 - РФ

    const allowRegion: boolean = CIS_REGIONS.includes(window.country_id);

    return allowCorp || allowRegion;
};

export const decodeEntities = (function() {
    const element = document.createElement('div');

    function decodeHTMLEntities(str = '') {
        let decodedString = str;

        // меняем br на /n
        decodedString = decodedString.replace(/<br\s*[\/]?>/gi, '\n');
        // выпиливаем теги script/html
        decodedString = decodedString.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        decodedString = decodedString.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        element.innerHTML = decodedString;
        decodedString = element.textContent;
        element.textContent = '';

        return decodedString;
    }

    return decodeHTMLEntities;
})();

export const getUserProfileURL = (slug: string): string => build_url(`${inGamecenter && !isGem ? '/gamecenter' : ''}/profile/${slug}`);

export const openURL = (event: Event, url: string): void => {
    if (inGamecenter) {
        openGCUrl(event, url);
    } else {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
};

export const openUserProfileURL = (event: Event, slug: string): void => {
    const profileURL = getUserProfileURL(slug);

    openURL(event, profileURL);
};

export const createGCGameURL = (gc_id: string): string => `mailrugames://show/${gc_id}`;

export const openGameProfileURL = (event: Event, game: Game): void => {
    if (inGamecenter) {
        const url = createGCGameURL(game.gc_id);

        openGCUrl(event, url);
    } else {
        openURL(event, game.url);
    }
};

export const setLinkTag = (url: string): void => {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link');

        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = url;
        link.onload = () => resolve();
        link.onerror = () => reject();

        document.head.appendChild(link);
    });
};

export const setScriptTag = (options: object): void => {
    const {
        src,
        inlineScript,
        onload,
        data,
        async,
        prepend,
        id,
        setToHead
    } = options;
    const script = document.createElement('script');
    const target = setToHead ? document.head : document.body;

    script.type = 'text/javascript';
    script.onload = onload;

    if (id) { // предотвращаем двойную вставку скрипта
        script.id = id;
        const prevScript = document.getElementById(id);

        if (prevScript) {
            return;
        }
    }

    if (src) {
        script.src = src;
    }

    if (async) {
        script.async = true;
    }

    if (data) {
        Object.keys(data).map((key) => {
            script.setAttribute([`data-${key}`], data[key]);
        });
    }

    if (inlineScript) {
        const textNode = document.createTextNode(inlineScript);

        script.appendChild(textNode);
    }

    if (prepend) {
        target.prepend(script);

        return;
    }

    target.appendChild(script);
};

export const calculateAge = (date = ''): ?number => {
    const birthDate = new Date(date);

    if (!isValidDate(birthDate)) {
        console.warn('Age calculation error: invalid date');

        return null;
    }

    const today = new Date();
    const month: number = today.getMonth() - birthDate.getMonth();

    let age: number = today.getFullYear() - birthDate.getFullYear();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

export const isOpenForWorld = (country_id: string): boolean => {
    const blockRegionList = ['188', '201'];
    const includes = blockRegionList.includes(country_id);

    return !(!country_id || includes);
};

export const getShortLocale = (lang: string): string => {
    const secondPartLangList = ['zh_CN', 'zh_TW', 'pt_BR'];
    const defaultLocale = isMyGames ? 'en' : 'ru';
    const langCode = lang.replace('-', '_');

    let locale = langCode.split('_')[0];

    if (secondPartLangList.includes(langCode)) {
        locale = langCode.split('_')[1].toLowerCase();
    }

    return locale ?? defaultLocale;
};

export const getUserCMPConsent = (): boolean => {
    if (!window.__cmp) {
        return true;
    }

    const consentExist = window.__cmp('consentStatus')?.consentExists;
    const userChoiceExists = window.__cmp('consentStatus')?.userChoiceExists;

    // c52 marketing cookie purpose
    const marketingCookie = window.__cmp('getCMPData')?.purposeConsents?.c52;

    return !!(consentExist && userChoiceExists && marketingCookie);
};
