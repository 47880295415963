import React from 'react';

import Label from './Label';

import type { LabelDiscountProps } from './types';
import type { FC } from 'react';

const LabelDiscount: FC<LabelDiscountProps> = ({
    discount,
    variant = 'lime',
    className,
    size= 'xs',
}) => {
    const label = `−${discount}%`;

    return (
        <Label
            className={className}
            variant={variant}
            label={label}
            size={size}
        />
    );
};

export default LabelDiscount;
