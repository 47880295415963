export const DEFAULT_CONTAINER_WIDTH = 154;

export const DEFAULT_CONTAINER_HEIGHT = 32;

export const INITIAL_X_PATHS = [2.80591, 21.1256, 73.6547, 150.322];

// First value is strokeWidth which need to be scaled too
export const INITIAL_Y_PATHS = [4, 29.2651, 26.7035, 22.5117, 27.0229];

export const DECIMALS_MULTIPLIER = 100000;
