import React, {
    useState, useRef, useLayoutEffect, forwardRef,
} from 'react';

import classNames from 'classnames';

import styles from './Input.module.css';
import InputIcon from './InputIcon';

import type { RefElementType, BaseInputProps } from './types';
import type { FC } from 'react';

const BaseInput: FC<BaseInputProps> = forwardRef<RefElementType, BaseInputProps>(({
    component: Component = 'input',
    status,
    beforeIcon,
    isIconAccent,
    afterIcon,
    onAfterIconClick,
    afterText,
    isLoading,
    isDisabled,
    onClear,
    wrapperClassName,
    inputClassName,
    suffix,
    ...restProps
}, ref) => {
    const [padding, setPadding] = useState(0);
    const rightRef = useRef<HTMLDivElement>(null);
    const isTextarea = Component === 'textarea';

    // Временно отключаем иконки для textarea
    const showIcons = !isTextarea;
    const showBeforeIcon = beforeIcon && showIcons;
    const showAfterIcon = (afterIcon || isLoading) && showIcons;
    const showAfterText = afterText && showIcons;
    const showClear = onClear && !isDisabled && showIcons;

    useLayoutEffect(() => {
        if (rightRef.current) {
            setPadding(rightRef.current?.offsetWidth || 0);
        }
    });

    return (
        <div
            className={classNames(styles.inputWrapper, wrapperClassName, {
                [styles.errorWrapper]: status === 'error',
                [styles.validWrapper]: status === 'valid',
                [styles.disabled]: isDisabled,
            })}
        >
            <Component
                {...restProps}
                className={classNames(styles.input, inputClassName, {
                    [styles.hasBeforeIcon]: showBeforeIcon,
                    [styles.hasAfterIcon]: showAfterIcon,
                    [styles.hasAfterText]: showAfterText && !showAfterText,
                    [styles.textarea]: isTextarea,
                })}
                disabled={isDisabled}
                style={{
                    paddingRight: `${padding}px`,
                }}
                ref={ref}
            />
            {suffix && restProps.value ? (
                <span
                    className={classNames(styles.suffix, styles.input, {
                        [styles.hasBeforeIcon]: showBeforeIcon,
                    })}
                >
                    <span className={classNames(styles.value)}>{restProps.value}</span>
                    {suffix}
                </span>
            ) : null}
            {showBeforeIcon && (
                <InputIcon
                    name={beforeIcon}
                    type="before"
                    accent={isIconAccent}
                />
            )}
            {(showAfterIcon || showAfterText || showClear)
                && (
                    <div className={styles.rightBlock} ref={rightRef}>
                        {showClear && (
                            <InputIcon
                                name="cancel"
                                type="clear"
                                onClick={onClear}
                            />
                        )}
                        {(showAfterIcon || showAfterText) && (
                            <InputIcon
                                name={isLoading ? 'loader' : afterIcon}
                                type="after"
                                text={afterText}
                                onClick={onAfterIconClick}
                            />
                        )}
                    </div>
                )}
        </div>
    );
});

export default BaseInput;
