import { useLayoutEffect, useState } from 'react';

export default function useMediaQuery(
    query: string,
): boolean {
    const getMatches = (matchQuery: string): boolean => window.matchMedia(matchQuery).matches;

    const [matches, setMatches] = useState<boolean>(() => getMatches(query));

    function handleChange() {
        setMatches(getMatches(query));
    }

    useLayoutEffect(() => {
        const matchMedia = window.matchMedia(query);

        handleChange();

        matchMedia.addEventListener('change', handleChange);

        return () => {
            matchMedia.removeEventListener('change', handleChange);
        };
    }, [query]);

    return matches;
}
