interface Options {
    path: number[];
    originalSize: number;
    containerSize: number;
    precision: number;
}

const scalePath = ({
    path,
    originalSize,
    containerSize,
    precision = 1,
}: Options) => {
    const multiplier = containerSize / originalSize;

    return path.map((pathValue) => {
        const multiplied = pathValue * multiplier;

        return Math.round(multiplied * precision) / precision;
    });
};

export default scalePath;
