import React from 'react';

import cn from 'classnames';

import Icon from '@atoms/Icon';

import styles from './Notice.module.css';

import type { NoticeProps } from './types';
import type { FC } from 'react';

const Notice: FC<NoticeProps> = ({
    title,
    subtitle,
    iconName,
    backgroundColor = '#262829',
    children,
}) => {
    const rootStyle = {
        backgroundColor,
    };

    return (
        <div
            className={cn(styles.root)}
            style={rootStyle}
        >
            {iconName && (
                <Icon className={styles.icon} name={iconName} />
            )}
            <div className={styles.content}>
                <div className={styles.texts}>
                    {title && <span className={styles.title}>{title}</span>}
                    {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
                </div>
                {children}
            </div>
        </div>
    );
};

export default Notice;
