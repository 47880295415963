import React from 'react';

import styles from '@atoms/Button/Button.module.css';
import cn from 'classnames';

import type { DividerProps } from './types';
import type { FC } from 'react';

const Divider: FC<DividerProps> = ({ className }) => (
    <span
        className={
            cn(styles.divider, className)
        }
    />
);

export default Divider;
