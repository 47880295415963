import React, {
    useEffect, useRef, useState,
} from 'react';

import Icon from '@atoms/Icon';
import cn from 'classnames';

import styles from './ExpandableBlock.module.css';
import { createContentStyleAttributes, createOverlayStyleAttributes } from './utils';

import type { ExpandableBlockProps } from './types';

const ExpandableBlock = ({
    children, buttonText, fadeColor, height, isExpanded, onExpand,
}: ExpandableBlockProps) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const expandableRef = useRef<HTMLDivElement | null>(null);

    const [isExpandable, setIsExpandable] = useState(false);

    const overlayStyleAttrs = fadeColor
        ? createOverlayStyleAttributes(fadeColor)
        : {};

    const contentStyleAttrs = height ? createContentStyleAttributes(height) : {};

    useEffect(() => {
        if (contentRef.current && expandableRef.current) {
            const contentHeight = contentRef.current.clientHeight;
            const expandableHeight = expandableRef.current.clientHeight;
            setIsExpandable(contentHeight < expandableHeight);
        }
    }, []);

    return (
        <>
            <div
                ref={contentRef}
                className={cn(styles.content, { [styles.contentExpanded]: isExpanded })}
                style={contentStyleAttrs}
            >
                <div ref={expandableRef}>
                    {children}
                </div>
                {isExpandable && !isExpanded && <div className={styles.overlay} style={overlayStyleAttrs} />}
            </div>

            {isExpandable && (
                <button className={cn(styles.button, { [styles.buttonActive]: isExpanded })} onClick={onExpand}>
                    {buttonText}
                    <Icon name="chevron-down-24" />
                </button>
            )}
        </>
    );
};

export default ExpandableBlock;
