const formatValue = (value: string | number) => {
    const parsedNumber = typeof value === 'string' ? parseInt(value, 10) : value;

    if (Number.isNaN(parsedNumber)) {
        return `${value}`;
    }

    if (parsedNumber > 99) {
        return '99+';
    }

    if (parsedNumber < 0) {
        return '0';
    }

    return `${parsedNumber}`;
};

export default formatValue;
