import React from 'react';

import cn from 'classnames';

import Icon from '@atoms/Icon';

import styles from './Label.module.css';

import type { Props } from './types';
import type { FC } from 'react';

const Label: FC<Props> = ({
    label,
    iconName,
    size = 'md',
    variant = 'blue',
    className,
}) => {
    const iconNameWithVariant = label ? `${iconName}-circle` : iconName;

    return (
        <div className={cn(
            styles.root,
            {
                [styles[`variant_${variant}`]]: variant,
                [styles[`size_${size}`]]: size,
                [styles.noText]: !label,
            },
            className,
        )}
        >
            {iconNameWithVariant && (
                <div className={cn(styles.icon)}>
                    <Icon name={iconNameWithVariant} />
                </div>
            )}
            {label && <span className={styles.label}>{label}</span>}
        </div>
    );
};

export default Label;
