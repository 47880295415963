import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import Icon from '@atoms/Icon';
import WrapperButton from '@atoms/WrapperButton';
import useBreakpoints from '@hooks/useBreakpoints';

import styles from './Accordeon.module.css';

import type { AccordeonProps, ContentNode } from './types';
import type { MouseEvent } from 'react';

function Accordeon({
    items,
    defaultValue,
    onExpand,
}: AccordeonProps) {
    const { tabletSmMax } = useBreakpoints();

    const [currentValue, setValue] = useState<string>('');

    const rootRef = useRef<HTMLDivElement>(null);

    function setCurrentItem(value: string, contentNode?: ContentNode) {
        if (!contentNode) {
            return;
        }

        if (value === currentValue) {
            setValue('');

            contentNode.style.height = '0';

            return;
        }

        contentNode.style.height = `${contentNode.scrollHeight}px`;

        setValue(value);
    }

    useEffect(() => {
        if (!defaultValue) {
            return;
        }

        const activeItemContent = rootRef.current?.getElementsByClassName(defaultValue)[0].childNodes[1] as ContentNode;

        setCurrentItem(defaultValue, activeItemContent);
    }, [tabletSmMax]);

    function handleClick(e: MouseEvent<HTMLButtonElement>) {
        const { dataset, nextSibling } = e.currentTarget;
        const { value } = dataset;

        if (!value) {
            return;
        }

        setCurrentItem(value, nextSibling as ContentNode);

        if (onExpand) {
            onExpand(value);
        }
    }

    return (
        <div ref={rootRef} className={styles.root}>
            {items.map((item) => !item.isDisabled && (
                <div
                    key={item.value}
                    className={classNames(styles.item, item.value, {
                        [styles.active]: item.value === currentValue,
                    })}
                >
                    <WrapperButton
                        className={styles.button}
                        data-value={item.value}
                        onClick={handleClick}
                    >
                        {item.title}
                        <Icon className={styles.icon} name={tabletSmMax ? 'chevron-down-24' : 'chevron-down-32'} />
                    </WrapperButton>
                    <div className={styles.content}>
                        <div className={styles.contentInner}>
                            {item.content}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Accordeon;
