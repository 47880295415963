import React from 'react';

import classNames from 'classnames';

import styles from './Button.module.css';
import ButtonGroup from './ButtonGroup/ButtonGroup';
import Divider from './Divider/Divider';

import type { ButtonProps, ButtonSubComponents } from './types';
import type { FC } from 'react';

const Button: FC<ButtonProps> & ButtonSubComponents = ({
    className,
    labelClassName,
    children,
    component: Component = 'button',
    size = 'md',
    appearance = 'accent',
    mode = 'primary',
    radius = 'default',
    isDisabled = false,
    isStretched = false,
    leftIcon,
    rightIcon,
    counter,
    ...restProps
}) => (
    <Component
        {...restProps}
        className={classNames(
            className,
            styles.button,
            styles[size],
            styles[`appearance-${appearance}`],
            styles[`mode-${mode}`],
            styles[`radius-${radius}`],
            {
                [styles.disabled]: isDisabled,
                [styles.stretched]: isStretched,
                [styles['icon-button']]: !children,
            },
        )}
    >
        {leftIcon}
        {children != null && (
            <span className={classNames(styles.label, labelClassName)}>
                {children}
            </span>
        )}
        {counter != null && (
            <span className={styles.counter}>
                {counter}
            </span>
        )}
        {rightIcon}
    </Component>
);

Button.Divider = Divider;
Button.Group = ButtonGroup;

export default Button;
