import React, { useState, useCallback, forwardRef } from 'react';

import BaseInput from './BaseInput';

import type { RefElementType, TextInputProps } from './types';
import type { FC } from 'react';

const TextInput: FC<TextInputProps> = forwardRef<RefElementType, TextInputProps>(({
    type,
    disabled,
    ...restProps
}, ref) => {
    const [currentType, setCurrentType] = useState(type || 'text');
    const isTypePass = currentType === 'password';

    const togglePassType = useCallback(() => {
        setCurrentType(isTypePass ? 'text' : 'password');
    }, [currentType]);

    return (
        <BaseInput
            {...(type === 'password' && {
                afterIcon: isTypePass ? 'view-outline' : 'hide-outline',
                onAfterIconClick: togglePassType,
            })}
            isDisabled={disabled}
            {...restProps}
            type={currentType}
            component="input"
            {...(ref && { ref })}
        />
    );
});

export default TextInput;
