import type { FormFieldProps } from '@atoms/Input/types';

const useFieldProps = (props: FormFieldProps) => {
    const { id, caption, captionId } = props;
    const captionLabelId = captionId || `caption-${id}`;
    const fieldProps = {
        id,
        ...(caption && {
            'aria-describedby': captionLabelId,
        }),
    };

    return {
        captionLabelId,
        fieldProps,
    };
};

export default useFieldProps;
