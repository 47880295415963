import React from 'react';

import cn from 'classnames';

import styles from './SkeletonBlock.module.css';

import type { SkeletonBlockProps } from './types';

function SkeletonBlock({
    appearance = 'primary',
    className,
    withAnimation = true,
    height,
    width,
    marginBottom,
    children,
}: SkeletonBlockProps) {
    return (
        <div
            className={cn(styles.skeletonBlock, styles[appearance], className, {
                [styles.withAnimation]: withAnimation,
            })}
            style={{
                height,
                width,
                marginBottom,
            }}
        >
            {children}
        </div>
    );
}

export default SkeletonBlock;
