// @ts-nocheck
import EventEmitter from 'events';

import { Broadcast } from './broadcast';
import { inGamecenter } from './utils';

let MasterSlave;

if (inGamecenter) {
    MasterSlave = {
        _master: false,
        _events: new EventEmitter(),

        isMaster: function() {
            return this._master;
        },

        on: function (eventName, listener) {
            MasterSlave._events.on(eventName, listener);
        },

        off: function (eventName, listener) {
            if (listener) {
                MasterSlave._events.removeListener(eventName, listener);
            } else {
                MasterSlave._events.removeAllListeners(eventName);
            }
        },

        init: function() {
            MasterSlave._master = window.location.pathname.search('/gamecenter/chat') === 0 || window.location.pathname.search('/app') === 0;
            MasterSlave._events.emit('master');
        }
    };
} else {
    MasterSlave = {
        _events: new EventEmitter(),
        broadcastHandle: null,
        tryMasterHandle: null,
        masterId: null,
        authIdentity: null, // initial auth
        debugFlag: process.env.NODE_ENV === 'development',

        _debug: function(...args: any): void {
            if (MasterSlave.debugFlag && window.console) {
                window.console.debug.apply(window, args);
            }
        },

        _becomeMaster: function() {
            MasterSlave._debug('become master');
            MasterSlave.masterId = Broadcast.selfId;
            MasterSlave._sendBroadcast();
            MasterSlave._events.emit('master');
        },

        _becomeDeadman: function() {
            MasterSlave._debug('become deadman');
            if (MasterSlave.isMaster()) {
                MasterSlave.masterId = 0;
                clearTimeout(MasterSlave.broadcastHandle);
                Broadcast.post('master_resign', {});
            }
            Broadcast.off('master_broadcast');
            Broadcast.off('master_resign');
            MasterSlave._events.emit('deadman');
        },

        _sendBroadcast: function() {
            if (!MasterSlave.isMaster()) {
                return;
            }
            if (MasterSlave.isAuthChanged()) {
                MasterSlave._becomeDeadman();

                return;
            }
            MasterSlave._debug('master broadcast', Broadcast.selfId);
            clearTimeout(MasterSlave.broadcastHandle);
            MasterSlave.broadcastHandle = setTimeout(MasterSlave._sendBroadcast, 1200);
            Broadcast.post('master_broadcast', {});
        },

        _tryBecomeMaster: function(delay) {
            const to = (delay || 0) + Math.round(Math.random() * 500);

            MasterSlave._debug('_tryBecomeMaster', delay);
            clearTimeout(MasterSlave.tryMasterHandle);
            if (MasterSlave.isAuthChanged()) {
                MasterSlave._becomeDeadman();

                return;
            }
            MasterSlave.tryMasterHandle = setTimeout(MasterSlave._becomeMaster, to);
        },

        _acceptMaster: function(msg) {
            if (!MasterSlave.isMaster() || msg.sender < Broadcast.selfId) {
                // если есть мастер, чье кун-фу сильнее...
                MasterSlave._debug('accept master', msg.sender);

                if (MasterSlave.isMaster()) {
                    MasterSlave._events.emit('slave');
                }

                MasterSlave.masterId = msg.sender;
                clearTimeout(MasterSlave.broadcastHandle);
                clearTimeout(MasterSlave.tryMasterHandle);
                MasterSlave._tryBecomeMaster(5000);
            }
        },

        isMaster: function() {
            return MasterSlave.masterId === Broadcast.selfId;
        },

        isAuthChanged: function() {
            if (!MasterSlave.authIdentityGetter) {
                return false;
            }

            return MasterSlave.authIdentity !== MasterSlave.authIdentityGetter();
        },

        on: function (eventName, listener) {
            MasterSlave._events.on(eventName, listener);
        },

        off: function (eventName, listener) {
            if (listener) {
                MasterSlave._events.removeListener(eventName, listener);
            } else {
                MasterSlave._events.removeAllListeners(eventName);
            }
        },

        init: function(authIdentityGetter) {
            MasterSlave.authIdentityGetter = authIdentityGetter;
            if (authIdentityGetter) {
                MasterSlave.authIdentity = authIdentityGetter();
            }
            Broadcast.on('master_broadcast', MasterSlave._acceptMaster);
            Broadcast.on('master_resign', MasterSlave._tryBecomeMaster);
            window.addEventListener('unload', MasterSlave._becomeDeadman, false);
            MasterSlave._tryBecomeMaster(2000);
        }
    };
}

function initSubscriptions() {
    MasterSlave.init();
}

function onDOMLoadedHandler() {
    initSubscriptions();
    document.removeEventListener('DOMContentLoaded', onDOMLoadedHandler);
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', onDOMLoadedHandler);
} else {
    initSubscriptions();
}

export {
    MasterSlave,
    MasterSlave as default,
};
