import useMediaQuery from './useMediaQuery';

const BREAKPOINTS_MAX = {
    mobileSmMax: '360px',
    mobileMdMax: '480px',
    tabletSmMax: '768px',
    tabletMdMax: '1179px',
    tabletXMdMax: '1280px',
    tabletLgMax: '1460px',
    desktopSmMax: '1600px',
    desktopMdMax: '1727px',
    desktopLgMax: '1920px',
};

const BREAKPOINTS_MIN = {
    mobileSmMin: '361px',
    mobileMdMin: '481px',
    tabletSmMin: '769px',
    tabletMdMin: '1180px',
    tabletXMdMin: '1281px',
    tabletLgMin: '1461px',
    desktopSmMin: '1601px',
    desktopMdMin: '1728px',
    desktopLgMin: '1921px',
};

export default function useBreakpoints() {
    const mobileSmMax = useMediaQuery(`(max-width: ${BREAKPOINTS_MAX.mobileSmMax})`);
    const mobileSmMin = useMediaQuery(`(min-width: ${BREAKPOINTS_MIN.mobileSmMin})`);

    const mobileMdMax = useMediaQuery(`(max-width: ${BREAKPOINTS_MAX.mobileMdMax})`);
    const mobileMdMin = useMediaQuery(`(min-width: ${BREAKPOINTS_MIN.mobileMdMin})`);

    const tabletSmMax = useMediaQuery(`(max-width: ${BREAKPOINTS_MAX.tabletSmMax})`);
    const tabletSmMin = useMediaQuery(`(min-width: ${BREAKPOINTS_MIN.tabletSmMin})`);

    const tabletMdMax = useMediaQuery(`(max-width: ${BREAKPOINTS_MAX.tabletMdMax})`);
    const tabletMdMin = useMediaQuery(`(min-width: ${BREAKPOINTS_MIN.tabletMdMin})`);

    const tabletXMdMax = useMediaQuery(`(max-width: ${BREAKPOINTS_MAX.tabletXMdMax})`);
    const tabletXMdMin = useMediaQuery(`(min-width: ${BREAKPOINTS_MIN.tabletXMdMin})`);

    const tabletLgMax = useMediaQuery(`(max-width: ${BREAKPOINTS_MAX.tabletLgMax})`);
    const tabletLgMin = useMediaQuery(`(min-width: ${BREAKPOINTS_MIN.tabletLgMin})`);

    const desktopSmMax = useMediaQuery(`(max-width: ${BREAKPOINTS_MAX.desktopSmMax})`);
    const desktopSmMin = useMediaQuery(`(min-width: ${BREAKPOINTS_MIN.desktopSmMin})`);

    const desktopMdMax = useMediaQuery(`(max-width: ${BREAKPOINTS_MAX.desktopMdMax})`);
    const desktopMdMin = useMediaQuery(`(min-width: ${BREAKPOINTS_MIN.desktopMdMin})`);

    const desktopLgMax = useMediaQuery(`(max-width: ${BREAKPOINTS_MAX.desktopLgMax})`);
    const desktopLgMin = useMediaQuery(`(min-width: ${BREAKPOINTS_MIN.desktopLgMin})`);

    return {
        /**
         * @deprecated TODO: будет удален
        */
        isMobile: tabletSmMax,
        /**
         * @deprecated TODO: будет удален
        */
        isDesktop: !tabletSmMax,

        isNavFolded: tabletLgMax,
        isNavUnfolded: !tabletLgMax,

        mobileSmMax,
        mobileSmMin,
        mobileMdMax,
        mobileMdMin,
        tabletSmMax,
        tabletSmMin,
        tabletMdMax,
        tabletMdMin,
        tabletXMdMax,
        tabletXMdMin,
        tabletLgMax,
        tabletLgMin,
        desktopSmMax,
        desktopSmMin,
        desktopMdMax,
        desktopMdMin,
        desktopLgMax,
        desktopLgMin,
    };
}
