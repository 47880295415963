import React, { Children, cloneElement } from 'react';

import classNames from 'classnames';

import styles from './ButtonGroup.module.css';

import type { ButtonGroupProps } from './types';
import type { FC, ReactElement } from 'react';

const ButtonGroup: FC<ButtonGroupProps> = ({
    children,
    className,
    type = 'separated',
    size = 'md',
    ...buttonsProps
}) => {
    const childrenLength = Children.count(children);

    function getRadius(index: number) {
        if (type !== 'segmented') {
            return 'round';
        }

        if (index === 0) {
            return 'round-left';
        }

        if (index === childrenLength - 1) {
            return 'round-right';
        }

        return 'none';
    }

    return (
        <div
            className={classNames(
                styles['button-group'],
                styles[`type-${type}`],
                styles[size],
            )}
        >
            {Children.map(children, (child, i) => cloneElement(child as ReactElement<any>, {
                size,
                radius: getRadius(i),
                ...buttonsProps,
            }))}
        </div>
    );
};

export default ButtonGroup;
