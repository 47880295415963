import React from 'react';

import cn from 'classnames';

import Icon from '@atoms/Icon';

import styles from './Loader.module.css';

import type { LoaderProps } from './types';
import type { FC } from 'react';

const Loader: FC<LoaderProps> = ({ size = '32', className, spinnerClassName }) => (
    <div className={cn(styles.wrapper, className)}>
        <div
            className={cn(styles.spinner, spinnerClassName, {
                [styles[`size-${size}`]]: size,
            })}
        >
            <Icon name="loader" />
        </div>
    </div>
);

export default Loader;
