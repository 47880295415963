import React from 'react';

import {
    DECIMALS_MULTIPLIER,
    DEFAULT_CONTAINER_HEIGHT,
    DEFAULT_CONTAINER_WIDTH,
    INITIAL_X_PATHS,
    INITIAL_Y_PATHS,
} from './constants';
import scalePath from './utils/scalePath';

import type { UnderlineSvgProps } from './types';
import type { FC } from 'react';

const UnderlineSvg: FC<UnderlineSvgProps> = ({
    containerWidth = DEFAULT_CONTAINER_WIDTH,
    containerHeight = DEFAULT_CONTAINER_HEIGHT,
}) => {
    const x = scalePath({
        path: INITIAL_X_PATHS,
        originalSize: DEFAULT_CONTAINER_WIDTH,
        containerSize: containerWidth,
        precision: DECIMALS_MULTIPLIER,
    });

    const [strokeWidth, ...y] = scalePath({
        path: INITIAL_Y_PATHS,
        originalSize: DEFAULT_CONTAINER_HEIGHT,
        containerSize: containerHeight,
        precision: DECIMALS_MULTIPLIER,
    });

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={containerWidth}
            height={containerHeight}
            viewBox={`0 0 ${containerWidth} ${containerHeight}`}
            fill="none"
        >
            <path
                d={`M${x[0]} ${y[0]}C${x[1]} ${y[1]} ${x[2]} ${y[2]} ${x[3]} ${y[3]}`}
                stroke="#A7DD00"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />
        </svg>
    );
};

export default UnderlineSvg;
