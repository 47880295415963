import React, { forwardRef } from 'react';

import cn from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import styles from './Tooltip.module.css';

import type { TooltipProps } from './types';
import type { TooltipRefProps } from 'react-tooltip';

const Tooltip = forwardRef<TooltipRefProps, TooltipProps>((props, ref) => {
    const {
        appearance,
        className,
    } = props;

    return (
        <ReactTooltip
            noArrow
            {...props}
            ref={ref}
            className={cn(styles.tooltip, styles[`appearance-${appearance}`], className)}
            opacity="1"
        />
    );
});

export default Tooltip;
