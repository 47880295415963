import jsonp from 'fetch-jsonp';

import {
    build_api_url,
    absoluteUrl,
    getCookie,
    getAuthHost,
    qsStringify
} from './utils';

function csrf_token() {
    if (window.CSRF_TOKEN) {
        return window.CSRF_TOKEN;
    }

    return getCookie('csrftoken') || '';
}

type objectType = {
    [key: string]: any
};
type ApiResponseType = {
    code?: number;
    status: string;
    token: string;
}

enum methods {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
    patch = 'PATCH'
}

const DEFAULT_CONTENT_TYPE = 'application/x-www-form-urlencoded';
const JSON_CONTENT_TYPE = 'application/json';

const getStringOpts = (opts: objectType = {}): string => {
    let arrayValues = '';

    // Add duplicate keys instead of stringify array values
    Object.keys(opts).forEach((key: string) => {
        const val = opts[key];

        if (Array.isArray(val)) {
            val.forEach((item: string) => {
                arrayValues += `&${key}=${item}`;
            });

            delete opts[key];
        }
    });

    let newOpts = qsStringify(opts);

    if (newOpts.length === 0) {
        // Replace 1st amp;
        arrayValues.replace('&', '');
    }

    newOpts += arrayValues;

    return newOpts;
};

const clearEmptyValues = (opts: objectType) => {
    Object.keys(opts).forEach((key: string) => {
        if (opts[key] == null) {
            delete opts[key];
        }
    });

    return opts;
};

const fetcher = (url: string, method: methods = methods.get, options: objectType = {}, noCreds?: boolean, isDoc?: boolean): Promise<any> => {
    const isGet = method === methods.get;
    const isDelete = method === methods.delete;
    const { body } = options;
    const isJSON = body?.isJSON;
    const isFormData = body && body instanceof FormData;
    const isBlob = options.responseType === 'blob';
    const isArrayBuffer = options.responseType === 'arrayBuffer';

    let { headers } = options;

    if (body?.headers) {
        // Remove headers from post body
        headers = { ...headers, ...body.headers };
        delete body.headers;
    }

    const needHeaders = !isGet || headers;
    const params: objectType = {
        ...needHeaders && {
            headers: {
                ...!isFormData && {
                    'Content-Type': isJSON ? JSON_CONTENT_TYPE : DEFAULT_CONTENT_TYPE
                },
                ...headers
            }
        }
    };

    // Remove null or undefined values
    options = clearEmptyValues(options);

    if (options.body) {
        options.body = clearEmptyValues(options.body);
    }

    // Remove isJSON setting
    if (isJSON) {
        delete options.body.isJSON;
    }

    if (isGet) {
        // Remove headers from get query params
        delete options.headers;

        if (Object.keys(options).length > 0) {
            url += (url.includes('?') ? '&' : '?') + getStringOpts(options);
        }
    } else {
        params.body = isFormData ? body : isJSON ? JSON.stringify(body) : getStringOpts(body);
    }

    return fetch(url, {
        method,
        credentials: noCreds ? 'omit' : 'include',
        ...params
    })
        .then((response: Response) => {
            if (response.ok) {
                const contentType = response.headers.get('content-type') || '';

                if (isDoc) {
                    // Pass content encoding for html document response
                    return {
                        response,
                        encoding: contentType.split('charset=')[1] || 'utf-8'
                    };
                }

                if (isBlob) {
                    return response.blob();
                }

                if (isArrayBuffer) {
                    return response.arrayBuffer();
                }

                return isDelete ? response.text() : response.json();

            }

            return Promise.reject(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const ajax = {
    get: (url: string, options?: objectType, noCreds?: boolean, isDoc?: boolean) => fetcher(url, methods.get, options, noCreds, isDoc).then((data) => data),
    post: (url: string, options?: objectType, noCreds?: boolean) => fetcher(url, methods.post, options, noCreds).then((data) => data),
    put: (url: string, options?: objectType, noCreds?: boolean) => fetcher(url, methods.put, options, noCreds).then((data) => data),
    delete: (url: string, options?: objectType, noCreds?: boolean) => fetcher(url, methods.delete, options, noCreds).then((data) => data),
    patch: (url: string, options?: objectType, noCreds?: boolean) => fetcher(url, methods.patch, options, noCreds).then((data) => data),
};

let sdcCookieExist = false;

function makeAPIResponseHandler(func: Function, url: string, resolve: Function, reject: Function) {
    const set_sdc_cookie = () => {
        if (sdcCookieExist) {
            func(resolve, reject);

            return false;
        }

        const SDC_URL = `//${getAuthHost('auth-ac')}/sdc`;
        const callback = 'callback' + (new Date().valueOf() + Math.floor(Math.random() * 1000));

        jsonp(`${SDC_URL}?from=${absoluteUrl(url)}`, {
            timeout: 10000,
            jsonpCallback: 'JSONP_call',
            jsonpCallbackFunction: callback,
        })
            .then(() => {
                sdcCookieExist = true;
                func(resolve, reject);
            })
            .catch((error) => {
                reject(error);
            });
    };

    return (response: ApiResponseType) => {
        if (response) {
            if (response.code === 8018) {
                set_sdc_cookie();
            } else {
                resolve(response);
            }
        } else {
            reject();
        }
    };
}

const Backend = {
    localhost_origin: 'https://common-api.gm3.dev-vkplay.ru',
    set_localhost_origin(hostname: string): void {
        this.localhost_origin = hostname;
    },
    api_get(url: string, opts?: objectType): Promise<any> {
        url = build_api_url(url, this.localhost_origin);

        return new Promise(function handleRequest(resolve, reject) {
            ajax.get(url, opts)
                .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                .catch((error) => reject(error));
        });
    },

    get(url: string, opts?: objectType): Promise<any> {
        return new Promise(function handleRequest(resolve, reject) {
            ajax.get(url, opts)
                .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                .catch((error) => reject(error));
        });
    },

    get_without_cred(url: string, opts?: objectType): Promise<any> {
        return new Promise(function handleRequest(resolve, reject) {
            ajax.get(url, opts, true)
                .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                .catch((error) => reject(error));
        });
    },

    api_post(url: string, opts?: objectType): Promise<any> {
        return get_csrf_jwt_token()
            .then(token => {
                url = build_api_url(url, this.localhost_origin);
                opts = {
                    ...{
                        csrfmiddlewaretoken: csrf_token(),
                        csrfmiddlewaretoken_jwt: token
                    },
                    ...opts
                };

                return new Promise(function handleRequest(resolve, reject) {
                    ajax.post(url, {
                        body: opts
                    })
                        .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                        .catch((error) => reject(error));
                });
            });
    },

    api_patch(url: string, opts?: objectType): Promise<any> {
        return get_csrf_jwt_token()
            .then(token => {
                url = build_api_url(url, this.localhost_origin);
                opts = {
                    ...{
                        csrfmiddlewaretoken: csrf_token(),
                        csrfmiddlewaretoken_jwt: token
                    },
                    ...opts
                };

                return new Promise(function handleRequest(resolve, reject) {
                    ajax.patch(url, {
                        body: opts
                    })
                        .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                        .catch((error) => reject(error));
                });
            });
    },

    api_delete(url: string): Promise<any> {
        return get_csrf_jwt_token()
            .then(() => {
                url = build_api_url(url, this.localhost_origin);

                return new Promise(function handleRequest(resolve, reject) {
                    ajax.delete(url)
                        .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                        .catch((error) => reject(error));
                });
            });
    },

    delete(url: string): Promise<any> {
        return get_csrf_jwt_token()
            .then(token => {
                return new Promise(function handleRequest(resolve, reject) {
                    ajax.delete(url, {
                        headers: {
                            'X-CSRFToken': csrf_token(),
                            'X-JWTCSRFToken': token,
                        }
                    })
                        .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                        .catch((error) => reject(error));
                });
            });
    },

    api_put(url: string, opts?: objectType): Promise<any> {
        return get_csrf_jwt_token()
            .then(token => {
                url = build_api_url(url, this.localhost_origin);
                opts = {
                    ...{
                        csrfmiddlewaretoken: csrf_token(),
                        csrfmiddlewaretoken_jwt: token
                    },
                    ...opts
                };

                return new Promise(function handleRequest(resolve, reject) {
                    ajax.put(url, {
                        body: opts
                    })
                        .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                        .catch((error) => reject(error));
                });
            });

    },

    remote_api_post(url: string, opts?: objectType): Promise<any> {
        return new Promise(function post(resolve, reject) {
            ajax.post(url, {
                body: opts
            })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    },

    doc_get(url: string, opts?: objectType): Promise<any> {
        return new Promise(function get(resolve, reject) {
            ajax.get(url, opts, true, true)
                .then((resp) => {
                    const { response, encoding } = resp;

                    if (encoding === 'utf-8') {
                        response.text()
                            .then((text: string) => {
                                if (text) {
                                    resolve(text);
                                } else {
                                    reject();
                                }
                            });
                    } else {
                        // Fix html encoding
                        // Use encoding from response headers
                        response.arrayBuffer()
                            .then((buffer: ArrayBuffer) => {
                                if (buffer) {
                                    const decoder = new TextDecoder(encoding);

                                    resolve(decoder.decode(buffer));
                                } else {
                                    reject();
                                }
                            });
                    }
                })
                .catch((error) => reject(error));
        });
    },

    simple_post(url: string, opts?: objectType): Promise<any> {
        return new Promise(function post(resolve, reject) {
            ajax.post(url, {
                body: opts
            }, true)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    },

    remote_api_get(url: string, opts?: objectType): Promise<any> {
        return new Promise(function get(resolve, reject) {
            ajax.get(url, opts)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },

    post(url: string, opts?: objectType): Promise<any> {
        return get_csrf_jwt_token()
            .then(token => {
                opts = {
                    ...{
                        csrfmiddlewaretoken: csrf_token(),
                        csrfmiddlewaretoken_jwt: token
                    },
                    ...opts
                };

                return new Promise(function handleRequest(resolve, reject) {
                    ajax.post(url, {
                        body: opts,
                        responseType: opts?.responseType,
                    })
                        .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                        .catch((error) => reject(error));
                });
            });
    },

    post_formdata(url: string, opts: objectType, { file, fileField = 'filedata' }: objectType): Promise<any> {
        return get_csrf_jwt_token()
            .then(token => {
                const formData = new FormData();

                opts = {
                    ...{
                        csrfmiddlewaretoken: csrf_token(),
                        csrfmiddlewaretoken_jwt: token
                    },
                    ...opts
                };
                for (const key in opts) {
                    if (opts[key]) {
                        formData.append(`${key}`, `${opts[key]}`);
                    }
                }

                formData.append(`${fileField}`, file);

                return new Promise(function handleRequest(resolve, reject) {
                    ajax.post(url, {
                        body: formData
                    })
                        .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                        .catch((error) => reject(error));
                });
            });
    },

    post_real_formdata(url: string, formdata: FormData): Promise<any> {
        return get_csrf_jwt_token()
            .then(token => {
                formdata.append('csrfmiddlewaretoken', csrf_token());
                formdata.append('csrfmiddlewaretoken_jwt', token);

                return new Promise(function handleRequest(resolve, reject) {
                    ajax.post(url, {
                        body: formdata
                    })
                        .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                        .catch((error) => reject(error));
                });
            });
    },

    put(url: string, opts?: objectType): Promise<any> {
        return new Promise(function handleRequest(resolve, reject) {
            ajax.put(url, opts)
                .then(makeAPIResponseHandler(handleRequest, url, resolve, reject))
                .catch((error) => reject(error));
        });
    },

    jsonp(url: string, opts?: objectType | string): Promise<any> {
        let optsQuery = opts;

        if (typeof opts !== 'string') {
            optsQuery = opts ? `?${(new URLSearchParams(opts)).toString()}` : '';
        } else {
            optsQuery = opts ? `?${optsQuery}` : '';
        }

        return new Promise((resolve, reject) => {
            jsonp(`${url}${optsQuery}`, {
                timeout: 10000
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

function get_csrf_jwt_token(): Promise<any> {
    const token = window.CSRF_JWT_TOKEN;

    return new Promise((resolve) => {
        if (token) {
            resolve(token);
        } else {
            Backend.api_get('/social/profile/user_token')
                .then((resp: ApiResponseType) => {
                    window.CSRF_JWT_TOKEN = resp.token;
                    resolve(resp.token);
                }, () => {
                    return resolve('');
                });
        }
    });
}

export {
    Backend,
    get_csrf_jwt_token,
    Backend as default,
};
