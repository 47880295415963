// @ts-nocheck
import EventEmitter from 'events';

import { HashUtils } from './hash_utils';
import { MasterSlave } from './master_slave';
import { Broadcast } from './broadcast';

const WSD = {
    id: null,
    properShardNumber: NaN, // server calculated shard number (workaround for HashUtils bugs)

    CODE_CID_DUP: 4001,
    CODE_RESHARD: 4002,
    CODE_NO_AUTH: 4003,

    socket: null,
    reconnectHandle: null,
    errorTimeout: 2 * 1000,
    minErrorTimeout: 2 * 1000,
    maxErrorTimeout: 300 * 1000,
    minPageReloadInterval: 3600 * 1000, // 1 hour
    reopens: 0,
    opened: false,
    events: new EventEmitter(),

    _log: function(...args) {
        if (window.console) {
            window.console.log(...args);
        }
    },

    _debug: function(...args) {
        if (window.console) {
            window.console.debug(...args);
        }
    },

    _error: function(...args) {
        if (window.console) {
            window.console.error(...args);
        }
    },

    on: function (eventName, listener) {
        WSD.events.on(eventName, listener);
    },

    off: function (eventName, listener) {
        if (listener) {
            WSD.events.removeListener(eventName, listener);
        } else {
            WSD.events.removeAllListeners(eventName);
        }
    },

    getShard: function() {
        let shardNumber;

        if (isNaN(WSD.properShardNumber)) {
            shardNumber = HashUtils.getShard(String(WSD.id), WSD.shards.length);
        } else {
            shardNumber = WSD.properShardNumber;
        }

        return WSD.shards[shardNumber];
    },

    getCid: function() {
        let cid = window.localStorage.getItem('ws_cid');

        if (!cid) {
            cid = String(Math.round(0xFFFFFFFF * Math.random()));
            window.localStorage.setItem('ws_cid', cid);
        }

        return cid;
    },

    getUrl: function() {
        const host = WSD.getShard();
        const cid = WSD.getCid();
        const proto = (window.location.protocol || '').match('^https') ? 'wss' : 'ws';
        const url = proto + '://' + host + '?cid=' + encodeURIComponent(cid);

        return url;
    },

    onWebsocketOpen: function(ev) {
        WSD._debug('[ws] opened', ev);
        WSD.emitOpen();
        Broadcast.post('ws', { type: 'open' });
    },

    onWebsocketClose: function(ev) {
        WSD._debug('[ws] closed', 'code:', ev.code, 'reason:', ev.reason, 'wasClean:', ev.wasClean);
        WSD.emitClose();
        Broadcast.post('ws', { type: 'close' });
        WSD.socket = null;
        if (ev.wasClean) {
            switch (ev.code) {
                case WSD.CODE_CID_DUP:
                    // cid duplicated with another browser of same user
                    // generate a new one and reconnect
                    window.localStorage.removeItem('ws_cid');
                    WSD.reconnect();
                    break;
                case WSD.CODE_RESHARD:
                    const properShardNumber = parseInt(ev.reason);

                    WSD._debug('[ws] reshard', 'new:', properShardNumber, 'prev:', WSD.properShardNumber);

                    if (!isNaN(properShardNumber)) {
                        if (isNaN(WSD.properShardNumber)) {
                            // first resharding error means
                            // we have bugs in HashUtils for some browsers
                            WSD.properShardNumber = properShardNumber;
                            WSD.reconnect();
                        } else {
                            // second resharding error means
                            // client have stale list of shards and should reload the page
                            const lastReload = parseInt(window.localStorage.getItem('ws_last_reload_ts'));
                            const now = (new Date()).getTime();

                            if (!lastReload || (now - lastReload) > WSD.minPageReloadInterval) {
                                WSD.pageReload();
                            }
                        }
                    }
                    break;
                case WSD.CODE_NO_AUTH:
                    if (window.gc_renew_cookie) {
                        // may reload window
                        window.gc_renew_cookie();
                    }
                    document.body.className += ' no_auth';
                    WSD.reconnect(true);
                    break;
                default:
                    // normal close, clear error status
                    WSD.errorTimeout = WSD.minErrorTimeout;

                    return;
            }
        } else {
            // server closed connection unexpectedly
            WSD.reconnect(true);
        }
    },

    onWebsocketMessage: function(ev) {
        const message = ev.data;

        WSD.emitMessage(message);
        Broadcast.post('ws', { type: 'message', message: message });
    },

    onCrossTabMessage: function(data) {
        switch (data.type) {
            case 'message':
                WSD.emitMessage(data.message);
                break;
            case 'open':
                WSD.emitOpen();
                break;
            case 'close':
                WSD.emitClose();
                break;
            default:
                WSD._error('unknown broadcast message', data);
        }
    },

    emitMessage: function(message) {
        message = JSON.parse(message);
        WSD._debug('[ws] message', message);
        WSD.events.emit('message', message);
    },

    emitOpen: function() {
        WSD.events.emit('open', WSD.reopens);
        WSD.reopens += 1;
        WSD.opened = true;
    },

    emitClose: function() {
        WSD.events.emit('close');
        WSD.opened = false;
    },

    isOpened: function() {
        return WSD.opened;
    },

    pageReload: function () {
        const now = (new Date()).getTime();

        window.localStorage.setItem('ws_last_reload_ts', String(now));
        window.location.reload();
    },

    connect: function() {
        clearTimeout(WSD.reconnectHandle);
        if (WSD.socket) {
            WSD.socket.close();
            WSD.socket = null;
        }
        try {
            WSD.socket = new WebSocket(WSD.getUrl());
            WSD.socket.onopen = function(ev) {
                WSD.onWebsocketOpen(ev);
            };
            WSD.socket.onclose = function(ev) {
                WSD.onWebsocketClose(ev);
            };
            WSD.socket.onerror = function(ev) {
                WSD._error('[ws] error', ev);
            };
            WSD.socket.onmessage = function(ev) {
                WSD.onWebsocketMessage(ev);
            };
        } catch (e) {
            WSD._error('[ws] error', e);
        }
    },

    reconnect: function(withError) {
        clearTimeout(WSD.reconnectHandle);
        if (withError) {
            if (WSD.errorTimeout === WSD.maxErrorTimeout) {
                WSD.pageReload();
            }

            WSD.errorTimeout = Math.min(WSD.maxErrorTimeout, WSD.errorTimeout * 2);
            const to = Math.round(WSD.errorTimeout * (0.75 + Math.random() / 2));

            WSD.reconnectHandle = setTimeout(WSD.connect, to);
        } else {
            WSD.reconnectHandle = setTimeout(WSD.connect);
        }
    },

    disconnect: function() {
        clearTimeout(WSD.reconnectHandle);
        if (WSD.socket) {
            WSD.socket.onopen = null;
            WSD.socket.onclose = null;
            WSD.socket.onerror = null;
            WSD.socket.onmessage = null;
            WSD.socket.close();
            WSD.socket = null;
        }
    },

    becomeMaster: function() {
        WSD._debug('[ws] is master');
        Broadcast.off('ws', WSD.onCrossTabMessage);
        WSD.connect();
    },

    becomeSlave: function() {
        WSD._debug('[ws] is slave');
        WSD.disconnect();
        Broadcast.on('ws', WSD.onCrossTabMessage);
    },

    becomeDeadman: function() {
        WSD._debug('[ws] is deadman');
        WSD.disconnect();
        Broadcast.off('ws', WSD.onCrossTabMessage);
    },

    init: function(id, shards) {
        if (!window.WebSocket || !id) {
            WSD._error('[ws] is not inited, id=', id);

            return;
        }

        WSD.id = id;
        WSD.shards = shards;
        MasterSlave.on('master', WSD.becomeMaster);
        MasterSlave.on('slave', WSD.becomeSlave);
        MasterSlave.on('deadman', WSD.becomeDeadman);

        if (MasterSlave.isMaster()) {
            WSD.becomeMaster();
        } else {
            WSD.becomeSlave();
        }
    }
};

export {
    WSD,
    WSD as default,
};
