import { getGemHost } from './utils';

export enum HostsActivityID {
    GamePage = '68',
    Catalog = '69',
    GOF = '70',
    Community = '71',
    Profile = '72',
    Support = '74',
}

export enum TimespentEventTypes {
    Start = 'startForegroundTimespent',
    Stop = 'stopTimespent',
}

type EventType = TimespentEventTypes.Start | TimespentEventTypes.Stop;

const TMR_ID_VKPLAY_EXTRA_DEFAULT = '3252498';
const STORAGE_TIMESPENT_NAME = 'showcase_timespent_started';

const getStorageTimespentStartedID = (): string|null => {
    try {
        return window.localStorage.getItem(STORAGE_TIMESPENT_NAME);
    } catch (error) {
        console.error('Error getting timespent status:', error);

        return null;
    }
};

const setStorageTimespent = (id?: string) => {
    // Сбросить или установить флаг о том, что timespent был запущен
    try {
        if (id) {
            window.localStorage.setItem(STORAGE_TIMESPENT_NAME, id);
        } else {
            window.localStorage.removeItem(STORAGE_TIMESPENT_NAME);
        }
    } catch (error) {
        console.error('Error setting timespent:', error);
    }
};

export const tmrPushTimespent = (type: EventType, activityId?: string|null): void => {
    const { hostname, pathname } = window.location;
    const isStore = hostname === getGemHost('store');
    const isGof = hostname === getGemHost('gof');
    const isCommunity = hostname === getGemHost('community');
    const isProfile = hostname === getGemHost('profile');
    const isSupport = hostname === getGemHost('support');
    const isGamePage = isStore && (pathname.includes('/play/game/') || pathname.includes('/gamecenter/game'));
    const isCatalog = isStore && pathname.includes('/play/') && !(pathname.includes('/play/game/') || pathname.includes('/play/my/') || pathname.includes('/play/promo/') || pathname.includes('/play/market/') || pathname.includes('/play/code/'));

    const pageId = isGamePage ? HostsActivityID.GamePage
        : isCatalog ? HostsActivityID.Catalog
            : isGof ? HostsActivityID.GOF
                : isCommunity ? HostsActivityID.Community
                    : isProfile ? HostsActivityID.Profile
                        : isSupport ? HostsActivityID.Support : '';

    const activity = activityId || pageId;

    if (activityId && activityId === pageId) {
        // Не останавливать и не перезапускать timespent при переходе в разделах одной страницы
        return;
    }

    if (type === TimespentEventTypes.Start) {
        setStorageTimespent(activity);
    } else {
        setStorageTimespent();
    }

    if (activity !== '') {
        const _tmr = window._tmr || (window._tmr = []);

        _tmr.push({
            id: TMR_ID_VKPLAY_EXTRA_DEFAULT,
            type,
            activity
        });

        console.log('⏱ tmr event sent:', type, TMR_ID_VKPLAY_EXTRA_DEFAULT, `siteId: ${activity}`);
    }
};

export const tmrStartTimespent = (isUpdate?: boolean): void => {
    const startedActivity = isUpdate ? getStorageTimespentStartedID() : null;

    tmrPushTimespent(TimespentEventTypes.Start, startedActivity);
};

export const tmrStopTimespent = (): void => {
    const startedActivity = getStorageTimespentStartedID();

    if (startedActivity) {
        tmrPushTimespent(TimespentEventTypes.Stop, startedActivity);
    }
};
