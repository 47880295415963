import React from 'react';

import styles from './Tooltip.module.css';

import type { TooltipContentProps } from './types';

const TooltipContent: React.FC<TooltipContentProps> = ({
    title = '',
    description = '',
    button,
    onButtonClick,
}) => (
    <>
        {title && <h3 className={styles.title}>{ title }</h3>}
        {description && <p className={styles.description}>{ description }</p>}
        {button && <div className={styles.button} onClick={onButtonClick}>{ button }</div>}
    </>
);

export default TooltipContent;
