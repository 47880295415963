// @ts-nocheck
import EventEmitter from 'events';

const Broadcast = (function () {
    const selfId = (new Date()).getTime();
    let storage = {};

    try {
        storage = window.localStorage;
    } catch (error) {
        console.error('[broadcast]', error);
    }

    const events = new EventEmitter();

    const _onStorage = function (ev) {
        const match = (ev.key || '').match(/^broadcast_(.*)$/);
        let channel;

        if (match) {
            channel = match[1];
        } else {
            return;
        }

        const data = JSON.parse(ev.newValue);

        if (data.sender === selfId) {
            return;
        }

        events.emit(channel, data);
    };

    const post = function (channel, data) {
        data.ts = (new Date()).getTime();
        data.sender = selfId;
        storage['broadcast_' + channel] = JSON.stringify(data);
    };

    const on = function (eventName, listener) {
        events.on(eventName, listener);
    };

    const off = function (eventName, listener) {
        if (listener) {
            events.removeListener(eventName, listener);
        } else {
            events.removeAllListeners(eventName);
        }
    };

    const init = function () {
        try {
            storage.setItem('test', '1');
            storage.removeItem('test');
        } catch (error) {
            storage = {};
        }

        window.addEventListener('storage', _onStorage, false);
    };

    return {
        selfId: selfId,
        post: post,
        on: on,
        off: off,
        init: init
    };
})();

function initSubscriptions() {
    Broadcast.init();
}

function onDOMLoadedHandler() {
    initSubscriptions();
    document.removeEventListener('DOMContentLoaded', onDOMLoadedHandler);
}


if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', onDOMLoadedHandler);
} else {
    initSubscriptions();
}

export {
    Broadcast,
    Broadcast as default,
};
