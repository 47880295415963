import React, { useEffect, useRef } from 'react';

import cn from 'classnames';
import ReactDOM from 'react-dom';

import Button from '@atoms/Button';
import Icon from '@atoms/Icon';

import styles from './Dialog.module.css';

import type { DialogProps } from '@atoms/Dialog/types';
import type { FC, SyntheticEvent } from 'react';

const Dialog: FC<DialogProps> = ({
    open,
    className = '',
    children,
    size = 'md',
    type = 'popup',
    title,
    onClose,
    domNode,
    modalOptions = {},
    zIndex = 9999,
    notCloseOnBackdropClick = false,
    hideCloseIcon = false,
}) => {
    const {
        successText, noSuccessText, description, onSuccess, isSuccessAccent,
    } = modalOptions;

    const injectNode = typeof domNode === 'string' ? document.querySelector<HTMLElement>(domNode) : domNode;

    const isDialog = type === 'dialog';
    const dialogRef = useRef<HTMLDivElement>(null);

    const handleClickBackDrop = (event: SyntheticEvent) => {
        if (notCloseOnBackdropClick) {
            return false;
        }

        if ((event.target as HTMLDivElement).classList.contains(styles.dialogWrapper)) {
            return onClose && onClose();
        }
    };

    const onSuccessClick = () => {
        if (onSuccess) {
            onSuccess();
        }
    };

    useEffect(() => {
        const keyEvent = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && !notCloseOnBackdropClick) {
                onClose();
            }
        };

        if (open) {
            if (injectNode) {
                injectNode.style.overflow = 'hidden';
            }

            document.body.style.overflow = 'hidden';
            document.addEventListener('keydown', keyEvent);
        }

        return () => {
            if (open) {
                if (injectNode) {
                    injectNode.style.overflow = 'initial';
                }

                document.body.style.overflow = 'initial';
                document.addEventListener('keydown', keyEvent);
            }
        };
    }, [open]);

    if (!open) {
        return null;
    }

    const renderContent = () => {
        if (isDialog) {
            return (
                <>
                    <p className={styles.description}>{ description }</p>
                    <div className={styles.bottom}>
                        <Button
                            onClick={isSuccessAccent ? onClose : onSuccessClick}
                            mode="link"
                            className={styles.btn}
                        >
                            {isSuccessAccent ? noSuccessText : successText}
                        </Button>
                        <Button
                            onClick={isSuccessAccent ? onSuccessClick : onClose}
                            className={styles.btn}
                        >
                            {isSuccessAccent ? successText : noSuccessText}
                        </Button>
                    </div>
                </>
            );
        }

        return children;
    };

    return ReactDOM.createPortal(
        // eslint-disable-next-line
        <div
            className={styles.dialogWrapper}
            style={{ zIndex }}
            onClick={handleClickBackDrop}
        >
            <div
                className={cn(styles.dialog, className, {
                    [styles[size]]: size,
                    [styles[`${type}Type`]]: type,
                })}
                ref={dialogRef}
            >
                {!hideCloseIcon && (
                    <button
                        className={styles.close}
                        onClick={onClose}
                        tabIndex={-1}
                    >
                        <Icon name="close" />
                    </button>
                )}
                { !!title && (
                    <div className={styles.header}>
                        <h3 className={styles.title}>{ title }</h3>
                    </div>
                )}
                <div className={styles.content}>
                    { renderContent() }
                </div>
            </div>
        </div>,
        injectNode || document.body,
    );
};

export default Dialog;
