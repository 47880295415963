import React, { useRef } from 'react';

import TooltipContent from './TooltipContent';
import TooltipWrapper from './TooltipWrapper';

import type { TooltipWrapperProps, TooltipContentProps } from './types';
import type { TooltipRefProps } from 'react-tooltip';

const TooltipWithContent: React.FC<TooltipWrapperProps & TooltipContentProps> = ({
    id,
    title = '',
    description = '',
    button,
    appearance = 'neutral',
    children,
    offset = 8,
    place = 'bottom',
    delayShow,
    delayHide = 300,
    isOpen,
}) => {
    const tooltipRef = useRef<TooltipRefProps>(null);

    const handleTooltipClose = () => {
        if (tooltipRef.current) {
            tooltipRef.current.close();
        }
    };

    return (
        <TooltipWrapper
            id={id}
            place={place}
            ref={tooltipRef}
            appearance={appearance}
            content={(
                <TooltipContent
                    title={title}
                    description={description}
                    button={button}
                    onButtonClick={handleTooltipClose}
                />
            )}
            offset={offset}
            clickable
            delayShow={delayShow}
            delayHide={delayHide}
            isOpen={isOpen}
        >
            { children }
        </TooltipWrapper>
    );
};

export default TooltipWithContent;
