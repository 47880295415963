import React from 'react';

import cn from 'classnames';

import styles from './Counter.module.css';
import formatValue from './utils/formatValue';

import type { Props } from './types';
import type { FC } from 'react';

const Counter: FC<Props> = ({
    className,
    children,
    mode = 'accent',
    size = 'md',
}) => (
    <div className={cn(styles.root, {
        [styles[`mode_${mode}`]]: mode,
        [styles[`size_${size}`]]: size,
    }, className)}
    >
        {formatValue(children)}
    </div>
);

export default Counter;
