import React, { useEffect, useState } from 'react';

import debounce from '@utils/debounce.ts';

import styles from './Underline.module.css';
import UnderlineSvg from './UnderlineSvg';

import type { Props } from './types';
import type { FC } from 'react';

const Underline: FC<Props> = ({ children }) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [element, setElement] = useState<HTMLSpanElement>();

    const calculateMultiplier = (_element: HTMLSpanElement) => {
        const { width, height } = _element.getBoundingClientRect();

        setContainerWidth(width);
        setContainerHeight(height);
    };

    const handleSetElement = (_element: HTMLSpanElement) => {
        setElement(_element);
    };

    useEffect(() => {
        if (!element) {
            return;
        }

        calculateMultiplier(element);

        const throttled = debounce(() => {
            calculateMultiplier(element);
        }, 200);

        window.addEventListener('resize', throttled);

        return () => {
            window.removeEventListener('resize', throttled);
        };
    }, [children, element]);

    return (
        <span ref={handleSetElement} className={styles.root}>
            <span className={styles.line}>
                <UnderlineSvg containerHeight={containerHeight} containerWidth={containerWidth} />
            </span>
            <span className={styles.content}>
                {children}
            </span>
        </span>
    );
};

export default Underline;
