import React from 'react';

import { formatPrice } from '@vkplay/shared';
import cn from 'classnames';

import styles from './Price.module.css';

import type { PriceProps } from './types';
import type { FC } from 'react';

const Price: FC<PriceProps> = ({
    className,
    children,
    currency = 'RUB',
    size = 'md',
    variant = 'lime',
    isSub,
    isStrike,
}) => (
    <span
        className={cn(styles.root, {
            [styles[`size_${size}`]]: size,
            [styles[`variant_${variant}`]]: variant,
            [styles.sub]: isSub,
            [styles.strike]: isStrike,
        }, className)}
    >
        {formatPrice(children, currency)}
    </span>
);

export default Price;
