import type { BlockHeight } from './types';
import type { CSSProperties } from 'react';

export const createContentStyleAttributes = (height: BlockHeight) => ({
    '--default-content-height-desktop': `${height.desktop}px`,
    '--default-content-height-tablet': `${height.tablet}px`,
    '--default-content-height-mobile': `${height.mobile}px`,
});

export const createOverlayStyleAttributes = (color: string) => ({
    '--fade-start-color': color,
    '--fade-end-color': `${color}00)`,
} as CSSProperties);
