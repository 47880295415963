import React from 'react';

import cn from 'classnames';

import styles from './WrapperButton.module.css';

import type { WrapperButtonProps } from './types';

function WrapperButton({
    children,
    onClick,
    className,
    ...restProps
}: WrapperButtonProps) {
    return (
        <button
            {...restProps}
            onClick={onClick}
            type="button"
            className={cn(styles.wrapperButton, className)}
        >
            {children}
        </button>
    );
}

export default WrapperButton;
