import React from 'react';

import cn from 'classnames';

import Icon from '@atoms/Icon';
import { LabelDiscount } from '@atoms/Label';
import { Price, PriceLabel } from '@atoms/Price';

import styles from './PriceManager.module.css';

import type { Props } from './types';
import type { FC } from 'react';

const PriceManager: FC<Props> = ({
    size = 'md',
    variant: _variant = 'lime',
    currency,
    actualValue,
    originalValue,
    discountValue,
    isCloudOnly,
    cloudOnlyLabel,
    isBought,
    boughtLabel,
    isReleaseSoon,
    releaseSoonLabel,
    freeLabel,
}) => {
    if (isCloudOnly && cloudOnlyLabel) {
        return (
            <PriceLabel
                variant="gray"
                label={cloudOnlyLabel}
                icon={<Icon name="cloud" />}
                size={size}
            />
        );
    }

    if (isBought && boughtLabel) {
        return (
            <PriceLabel
                variant="gray"
                label={boughtLabel}
                icon={<Icon name="check-circle" />}
                size={size}
            />
        );
    }

    if (isReleaseSoon && releaseSoonLabel) {
        return (
            <PriceLabel
                variant="gray"
                label={releaseSoonLabel}
                icon={<Icon name="time-circle" />}
                size={size}
            />
        );
    }

    if (!actualValue && freeLabel) {
        return (
            <PriceLabel
                variant="lime"
                label={freeLabel}
                size={size}
            />
        );
    }

    if (!actualValue) {
        return null;
    }

    const shouldShowDiscount = originalValue && discountValue;

    const variant = !shouldShowDiscount ? 'whiteInvariable' : _variant;

    return (
        <div
            className={cn(styles.root, {
                [styles[`size_${size}`]]: size,
            })}
        >
            <Price variant={variant} size={size} currency={currency}>
                {actualValue}
            </Price>
            {shouldShowDiscount ? (
                <>
                    <Price
                        className={styles.originalPrice}
                        variant="gray"
                        currency={currency}
                        size={size}
                        isSub
                        isStrike
                    >
                        {originalValue}
                    </Price>
                    <LabelDiscount variant={variant} size={size} discount={discountValue} />
                </>
            ) : null}
        </div>
    );
};

export default PriceManager;
