import React from 'react';

import { SPECIAL_LABEL_TYPES } from './constans';
import Label from './Label';

import type { LabelSpecialProps } from './types';
import type { FC } from 'react';

const LabelSpecial: FC<LabelSpecialProps> = ({
    type,
    label,
    size,
    className,
}) => {
    if (!SPECIAL_LABEL_TYPES[type]) {
        return null;
    }

    const { variant, iconName } = SPECIAL_LABEL_TYPES[type];

    return (
        <Label
            className={className}
            variant={variant}
            iconName={iconName}
            label={label}
            size={size}
        />
    );
};

export default LabelSpecial;
