import React from 'react';

import classNames from 'classnames';

import Button from '@atoms/Button';
import Icon from '@atoms/Icon';
import styles from '@atoms/Input/Input.module.css';
import { TooltipWrapper } from '@atoms/Tooltip';

import type { IconProps } from './types';
import type { FC, MouseEvent } from 'react';

const InputIcon: FC<IconProps> = ({
    name,
    type = 'before',
    text,
    accent,
    className,
    onClick,
}) => {
    const isText = !name && text;
    const isLoader = name === 'loader';
    const tooltipId = name ? `${name}-${Math.random().toString(36).substr(2, 9)}` : '';

    const handleClick = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (onClick) {
            onClick(e);
        }
    };

    if (isText) {
        return (
            <span className={styles.afterText}>{text}</span>
        );
    }

    if (!name) {
        return null;
    }

    const AfterBtn = (
        <Button
            type="button"
            appearance="neutral"
            mode="link"
            onClick={handleClick}
            className={classNames(styles.iconAfter, className, {
                [styles.loader]: isLoader,
            })}
            leftIcon={<Icon name={name} />}
            isDisabled={isLoader || !onClick}
        />
    );

    if (type === 'after') {
        if (text && !isLoader) {
            return (
                <TooltipWrapper
                    id={tooltipId}
                    content={text}
                >
                    {AfterBtn}
                </TooltipWrapper>
            );
        }

        return AfterBtn;
    }

    if (type === 'clear') {
        return (
            <Button
                type="button"
                appearance="alpha"
                mode="primary"
                size="xs"
                onClick={handleClick}
                radius="round"
                className={classNames(styles.iconClear, className)}
                leftIcon={<Icon name={name} />}
            />
        );
    }

    return (
        <div
            className={classNames(styles.iconBefore, className, {
                [styles.accent]: accent,
            })}
        >
            <Icon name={name} />
        </div>
    );
};

export default InputIcon;
