import React, { forwardRef } from 'react';

import classNames from 'classnames';

import Button from '@atoms/Button';
import Icon from '@atoms/Icon';

import useFieldProps from './hooks/useFieldProps';
import styles from './Input.module.css';
import Textarea from './Textarea';
import TextInput from './TextInput';

import type {
    RefElementType,
    FormFieldSubComponents,
    FormInputProps,
    FormFieldProps,
    FormTextareaProps,
} from './types';
import type { FC } from 'react';

const FormField: FC<FormFieldProps> & FormFieldSubComponents = ({
    children,
    label,
    subhead,
    caption,
    captionId,
    required,
    fieldClassName,
    onRemove,
    id,
    status,
}) => {
    const { captionLabelId } = useFieldProps({
        id,
        caption,
        captionId,
    });

    return (
        <div
            className={classNames(styles.formField, fieldClassName, {
                [styles.valid]: status === 'valid',
                [styles.error]: status === 'error',
            })}
        >
            {label
                && (
                    <label htmlFor={id} className={styles.label}>
                        {label}
                        {required
                            && <span className={styles.labelRequired}>*</span>}
                        {subhead
                            && <span className={styles.subhead}>{subhead}</span>}
                    </label>
                )}

            <div className={styles.formFieldInput}>
                {children}
                {onRemove
                    && (
                        <Button
                            type="button"
                            className={styles.removeBtn}
                            appearance="neutral"
                            mode="link"
                            onClick={onRemove}
                            leftIcon={<Icon name="cancel" />}
                        />
                    )}
            </div>

            {caption && (
                <span
                    id={captionLabelId}
                    className={styles.caption}
                >
                    {caption}
                </span>
            )}
        </div>
    );
};

const FormInput: FC<FormInputProps> = forwardRef<RefElementType, FormInputProps>(({
    id,
    caption,
    captionId,
    label,
    subhead,
    onRemove,
    fieldClassName,
    ...restProps
}, ref) => {
    const { captionLabelId, fieldProps } = useFieldProps({
        id,
        caption,
        captionId,
    });

    const props = {
        ...restProps,
        id,
        caption,
        captionId: captionLabelId,
        label,
        subhead,
        onRemove,
        fieldClassName,
    };

    return (
        <FormField {...props}>
            <TextInput
                {...restProps}
                {...fieldProps}
                {...(ref && { ref })}
            />
        </FormField>
    );
});

const FormTextarea: FC<FormTextareaProps> = forwardRef<RefElementType, FormTextareaProps>(({
    id,
    caption,
    captionId,
    label,
    subhead,
    onRemove,
    fieldClassName,
    ...restProps
}, ref) => {
    const { captionLabelId, fieldProps } = useFieldProps({
        id,
        caption,
        captionId,
    });

    const props = {
        ...restProps,
        id,
        caption,
        captionId: captionLabelId,
        label,
        subhead,
        onRemove,
        fieldClassName,
    };

    return (
        <FormField
            {...props}
        >
            <Textarea
                {...restProps}
                {...fieldProps}
                {...(ref && { ref })}
            />
        </FormField>
    );
});

FormField.Input = FormInput;
FormField.Textarea = FormTextarea;

export default FormField;
