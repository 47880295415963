import type { SpecialTypes, SpecialTypeData } from './types';

// eslint-disable-next-line import/prefer-default-export
export const SPECIAL_LABEL_TYPES: Record<SpecialTypes, SpecialTypeData> = {
    vkp: {
        variant: 'blackAlpha',
        iconName: 'check',
    },
    download: {
        variant: 'whiteAlpha',
        iconName: 'download',
    },
    cloud: {
        variant: 'whiteAlpha',
        iconName: 'cloud',
    },
    dev: {
        variant: 'blackAlpha',
        iconName: 'in-development',
    },
    key: {
        variant: 'whiteInvariable',
        iconName: 'key',
    },
    bundle: {
        variant: 'bundle',
        iconName: 'kit',
    },
    earlyAccess: {
        variant: 'earlyAccess',
        iconName: 'hourglass',
    },
    dlc: {
        variant: 'dlc',
        iconName: 'game',
    },
    demo: {
        variant: 'demo',
        iconName: 'demo',
    },
    laboratory: {
        variant: 'laboratory',
        iconName: 'education',
    },
    browser: {
        variant: 'whiteAlpha',
        iconName: 'browser',
    },
};
