import React from 'react';

import cn from 'classnames';

import styles from './Price.module.css';

import type { PriceLabelProps } from './types';
import type { FC } from 'react';

const PriceLabel: FC<PriceLabelProps> = ({
    className,
    icon,
    label,
    size,
    variant,
}) => (
    <div
        className={cn(styles.priceLabel, {
            [styles[`size_${size}`]]: size,
            [styles[`variant_${variant}`]]: variant,
        }, className)}
    >
        {icon && <div className={styles.icon}>{icon}</div>}
        <span className={styles.label}>{label}</span>

    </div>
);

export default PriceLabel;
